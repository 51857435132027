import { onDesktop } from '../responsive'

export const prepareToolBar = theme => {
  return {
    defaultProps: {
      disableGutters: true
    },
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        height: theme.spacing(7.5),
        minHeight: `${theme.spacing(7.5)} !important`,
        [onDesktop]: {
          height: theme.spacing(10),
          minHeight: `${theme.spacing(10)} !important`
        }
      },
      butterBar: {
        color: theme.palette.primary.main,
        justifyContent: 'center',
        alignItems: 'center',
        height: theme.spacing(4),
        minHeight: `${theme.spacing(4)} !important`,
        [onDesktop]: {
          height: theme.spacing(5),
          minHeight: `${theme.spacing(5)} !important`
        }
      }
    }
  }
}
