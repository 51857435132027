import { experimental_sx as sx } from '@mui/material/styles'
import { onDesktop } from '../responsive'

export const prepareAutocomplete = theme => {
  return {
    styleOverrides: {
      inputRoot: sx({
        ...theme.typography.body2,
        px: 1,
        py: 0.625,
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1
        },
        '& .MuiAutocomplete-input': {
          pt: 0.875,
          pb: 0.75,
          pr: 0.5,
          pl: 0.75,
          [onDesktop]: {
            pb: 0.875
          }
        }
      }),
      paper: {
        boxShadow: theme.shadows[3],
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      },
      listbox: sx({
        '& .MuiAutocomplete-option': {
          py: 1,
          '&.Mui-focused': {
            backgroundColor: theme.palette.primary.light
          }
        }
      }),
      option: sx({
        ...theme.typography.button,
        color: theme.palette.gray.elysian,
        textTransform: 'uppercase'
      })
    }
  }
}
