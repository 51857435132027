import { cloneDeep } from 'lodash'

export const ORDER_AWAITING_PAYMENT = 'Awaiting Payment' // invoice for this gorder was created, awaiting payment success webhook
export const ORDER_JUST_PLACED_STATE = 'just placed'

/* Intransit & in formulation are for the current order. We just charged them on the order and we are preparing/formulating or shipped out */
export const ORDER_FORMULATED_STATE = 'formulating'

/* Intransit & in formulation are for the current order. We just charged them on the order and we are preparing/formulating or shipped out */
export const ORDER_IN_TRANSIT_STATE = 'in transit'

/* orders already delivered to the user */
export const ORDER_DELIVERED_STATE = 'delivered'
export const ORDER_SHIPPED_STATE = 'Shipped'

export const ORDER_ONE_TIME_PURCHASE_OR_CANCELED_STATE = 'One time purchaser & cancelled'

const SHIPPING_STATUSES_PENDING = 'Pending' // New shipments added that are pending to track, or new shipments without tracking information available yet

const SHIPPING_STATUSES_EXPIRED = 'Expired' // Shipment has no tracking information for 30 days since added.

const SHIPPING_STATUSES_EXCEPTION = 'Exception' // Custom hold, undelivered, returned shipment to sender or any shipping exceptions.

const SHIPPING_STATUSES_AVAILABLE_FOR_PICKUP = 'AvailableForPickup' // The package arrived at a pickup point near you and is available for pickup.

const SHIPPING_STATUSES_DELIVERED = 'Delivered' // The shipment was delivered successfully.

const SHIPPING_STATUSES_ATTEMPT_FAILED = 'AttemptFail' // Carrier attempted to deliver but failed, and usually leaves a notice and will try to delivery again.

const SHIPPING_STATUSES_OUT_FOR_DELIVERY = 'OutForDelivery' // Carrier is about to deliver the shipment , or it is ready to pickup.

const SHIPPING_STATUSES_IN_TRANSIT = 'InTransit' // Carrier has accepted or picked up shipment from shipper. The shipment is on the way

const SHIPPING_STATUSES_INFO_RECEIVED = 'InfoReceived' // Carrier has received request from shipper and is about to pick up the shipment.

const ORDER_SHIPPING_STATUS_MAPPING = {
  [SHIPPING_STATUSES_PENDING]: ORDER_FORMULATED_STATE,
  [SHIPPING_STATUSES_EXPIRED]: ORDER_FORMULATED_STATE,
  [SHIPPING_STATUSES_EXCEPTION]: ORDER_FORMULATED_STATE,
  [SHIPPING_STATUSES_AVAILABLE_FOR_PICKUP]: ORDER_FORMULATED_STATE,
  [SHIPPING_STATUSES_DELIVERED]: ORDER_DELIVERED_STATE,
  [SHIPPING_STATUSES_ATTEMPT_FAILED]: ORDER_FORMULATED_STATE,
  [SHIPPING_STATUSES_OUT_FOR_DELIVERY]: ORDER_FORMULATED_STATE,
  [SHIPPING_STATUSES_IN_TRANSIT]: ORDER_FORMULATED_STATE,
  [SHIPPING_STATUSES_INFO_RECEIVED]: ORDER_FORMULATED_STATE
}

/**
 * the order.shippingStatus is an object o diff states (see ORDER_SHIPPING_STATUS_MAPPING) and we need to get the key of the object
 * @param order
 * @returns {*|string}
 */
const getShippingStatus = order => {
  const status =
    order?.shippingStatus && Object.keys(order?.shippingStatus).length === 1
      ? Object.keys(order?.shippingStatus)[0]
      : null
  return status || SHIPPING_STATUSES_PENDING
}

const getDateFromShippingStatus = order => {
  return order?.shippingStatus && order?.shippingStatus[getShippingStatus(order)]
}

const handleShippedStatus = (order, now) => {
  let shippingStatus
  let orderStatusMapping

  shippingStatus = getShippingStatus(order)
  orderStatusMapping = ORDER_SHIPPING_STATUS_MAPPING[shippingStatus]
  let orderState =
    orderStatusMapping === ORDER_DELIVERED_STATE
      ? ORDER_DELIVERED_STATE
      : order?.trackingNumber
      ? ORDER_IN_TRANSIT_STATE
      : ORDER_FORMULATED_STATE

  // there is a bug in afterShip that is not updating the ShippingStatus sometimes
  // to fix it, we are going to check if the order has status is in "inTransit" and pass 3 weeks
  // we also are checkint the order?.status because AfterShip return null for all order older than 120 days
  // so we asume that if the order.status is shipped then it was taken for AfterShip for delivery
  if (orderState === ORDER_IN_TRANSIT_STATE || order?.status === ORDER_SHIPPED_STATE) {
    const threeWeeksAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 21)
    const dateFromShipment = getDateFromShippingStatus(order)
    const shippedDate = dateFromShipment ? new Date(getDateFromShippingStatus(order)) : new Date()
    if (shippedDate < threeWeeksAgo) {
      orderState = ORDER_DELIVERED_STATE
    }
  }

  return orderState
}

/**
 * the order is a merge between
 * * state.account.orders
 * * response of endpoint await getOrderDetails(orderId)
 * the object should have this structure
 *
 * const orderData = await getOrderDetails(orderId)
 * const orderInfo = orders && orders.find(o => o.order === orderId)
 * const order = {
 *          ...orderData.data,
 *         isEyeCream: orderInfo?.isEyeCream,
 *         isSerum: orderInfo?.isSerum,
 *         description: orderInfo?.description,
 *         season: orderInfo?.isEyeCream ? orderInfo?.description : orderInfo?.season,
 *         status: orderInfo?.status
 * }
 *
 * @param order
 * @returns {null|string}
 */
export const getOrderState = (order, now) => {
  switch (order?.status) {
    case 'Pending':
    case 'Awaiting Payment':
      return ORDER_FORMULATED_STATE
    case 'ReadyToShip':
      return order?.trackingNumber ? ORDER_IN_TRANSIT_STATE : ORDER_FORMULATED_STATE
    case 'Shipped':
      return handleShippedStatus(order, now)
    case 'Delivered':
      return ORDER_DELIVERED_STATE
    default:
      return null
  }
}

export const getOrdersGroupedByState = (orders, now = new Date()) => {
  const ordersGroupedByState = {
    [ORDER_JUST_PLACED_STATE]: [],
    [ORDER_FORMULATED_STATE]: [],
    [ORDER_IN_TRANSIT_STATE]: [],
    [ORDER_DELIVERED_STATE]: [],
    [ORDER_ONE_TIME_PURCHASE_OR_CANCELED_STATE]: []
  }

  orders?.forEach(order => {
    const orderState = getOrderState(order, now)
    if (orderState) {
      ordersGroupedByState[orderState].push(order)
    } else {
      ordersGroupedByState[ORDER_ONE_TIME_PURCHASE_OR_CANCELED_STATE].push(order)
    }
  })

  return ordersGroupedByState
}

/**
 * @param orders
 * @param maxNumberOfMonths
 * @returns {*}
 */
export const filterOrdersByMaxNumberOfMonths = (orders, maxNumberOfMonths) => {
  const now = new Date()
  const aNumberOfMonthsAgo = new Date(
    now.getFullYear(),
    now.getMonth() - maxNumberOfMonths,
    now.getDate()
  )
  return orders.filter(order => new Date(order.createdAt) > aNumberOfMonthsAgo)
}

export const filterOrdersByDuplicatedSubscriptionId = (orders = []) => {
  const subscriptionIds = []
  const ordersCloned = cloneDeep(orders)
  ordersCloned.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt)
  })

  return ordersCloned.filter(order => {
    if (!order.subscriptionId) {
      return true
    }
    if (subscriptionIds.includes(order.subscriptionId)) {
      return false
    }
    subscriptionIds.push(order.subscriptionId)
    return true
  })
}
