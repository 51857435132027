import { keysToUpperCase } from '../misc'

/**
 * returns the first free-gift coupon details or undefined if there is not one
 * @param couponCodes
 * @param couponDetailsByCode
 * @returns {undefined|*}
 */
export const lookForFreeGiftCouponDetails = (couponCodes, couponDetailsByCode) => {
  if (!couponCodes?.length || !couponDetailsByCode) {
    return undefined
  }
  const couponDetailsByCodeWithUpperCaseCodes = keysToUpperCase(couponDetailsByCode)

  const freeGiftCouponCode = couponCodes.find(code => {
    const couponDetails = couponDetailsByCodeWithUpperCaseCodes[code?.toUpperCase()]
    return (
      couponDetails?.productsThatAllowFreeGiftWithPurchase?.length &&
      couponDetails?.priceIdsForFreeWithCouponApplying?.length
    )
  })

  return freeGiftCouponCode
    ? couponDetailsByCodeWithUpperCaseCodes[freeGiftCouponCode.toUpperCase()]
    : undefined
}

/**
 * returns the amount of the lineIems that are in priceIdsForFreeWithCouponApplying
 * @param lineItems
 * @param priceIdsForFreeWithCouponApplying
 */
export const getTotalFreeGiftsAmount = (lineItems, priceIdsForFreeWithCouponApplying) => {
  const total = (lineItems || []).reduce((acc, lineItem) => {
    return priceIdsForFreeWithCouponApplying?.includes(lineItem?.entityId)
      ? (lineItem?.amount || 0) + acc
      : acc
  }, 0)
  return total
}
