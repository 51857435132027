/**
 *
 * @param query a string
 * @param categoriesOrSubcategories An array of {questions: Array of {question: string, answer: string} , subcategories: Array of {questions: Array of {question: string, answer: string}} }
 * @returns array of {questions: Array of {question: string, answer: string} , subcategories: Array of {questions: Array of {question: string, answer: string}} }
 */
export function filterFaqCategories(query = '', categoriesOrSubcategories) {
  return categoriesOrSubcategories
    .map(categoryOrSubcategory => {
      const questions = !categoryOrSubcategory?.questions?.length
        ? categoryOrSubcategory?.questions
        : categoryOrSubcategory.questions.filter(
            ({ question, answer }) =>
              question?.toLowerCase().includes(query?.toLowerCase()) ||
              answer?.toLowerCase().includes(query?.toLowerCase())
          )
      const subcategories = !categoryOrSubcategory?.subcategories?.length
        ? categoryOrSubcategory?.subcategories
        : filterFaqCategories(query, categoryOrSubcategory.subcategories)
      return questions?.length || subcategories?.length
        ? { ...categoryOrSubcategory, questions, subcategories }
        : null
    })
    .filter(category => category)
}

/**
 *
 * This function calls filterFaqCategories, but it uses builderIo state and context
 * This function should only be used from builderIo JS scripts. Since it passes  builder context and state
 * @param state
 * @param context
 */
export function filterFaqInBuilderIoContext(state, context) {
  // Prefer FAQs input from the content input field,
  // fall back to content API response.
  const rawFaqs = state?.faqs?.value?.data?.categories || state?.faqs?.data?.categories || []

  if ((state.faqs?.value?.data || state.faqs?.data) && !state.faqsFetched) {
    state.filteredFaqs = rawFaqs
    state.faqsFetched = true
  }

  if (state.query === context.query || !state.faqsFetched) {
    return
  }
  // Only re-filter if the query has changed or
  // we haven't filtered before.
  context.query = state.query

  // Shortcut: reset filtered FAQs when query is empty.
  if (!state.query || state.query?.length === 0) {
    state.filteredFaqs = rawFaqs
    return
  }
  const filteredFaqs = filterFaqCategories(state.query, rawFaqs)
  state.filteredFaqs = filteredFaqs
}
