// TODO move this to configuration
const BASE_SKUS_AND_YOTPO_ID_MAP = {
  CLN: '823200',
  SPF: '823300',
  CRM: '823400'
}

export const refreshYotpoWidgets = () => {
  if (window.yotpo && window.Yotpo && window.yotpo.initialized) {
    try {
      const api = new window.Yotpo.API(window.yotpo)
      api.refreshWidgets()
    } catch (e) {
      console.error(`Yotpo error: `, e)
    }
  }
}

const getSkuFromSkuDesc = sku_desc => {
  const sku = sku_desc?.substring(4, 7)
  return sku
}

export const getYotpoIdFromSku = sku_desc => {
  const sku = getSkuFromSkuDesc(sku_desc)
  const skuBasesRegex = /(CLN|SPF|CRM)/
  const matches = sku?.match(skuBasesRegex)
  return matches && matches[0] && BASE_SKUS_AND_YOTPO_ID_MAP[matches[0]]
}
