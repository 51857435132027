export const prepareBadge = theme => {
  return {
    defaultProps: {
      color: 'primary'
    },
    styleOverrides: {
      root: {
        '& .MuiBadge-badge': {
          ...theme.typography.button,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.gray.white,
          width: theme.spacing(2.25),
          minWidth: theme.spacing(2.25),
          height: theme.spacing(2.25)
        }
      }
    }
  }
}
