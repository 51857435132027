export const prepareAppBar = () => {
  return {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: 'unset'
      }
    }
  }
}
