import { alpha } from '@mui/material'
import { onDesktop } from '../responsive'

export const prepareLinearProgress = theme => {
  return {
    styleOverrides: {
      root: {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        borderRadius: theme.spacing(1.25),
        height: 19,
        [onDesktop]: {
          borderRadius: theme.spacing(0.25),
          height: 4
        }
      },
      bar1Determinate: {
        backgroundColor: theme.palette.primary.current
      }
    }
  }
}
