import { experimental_sx as sx } from '@mui/material/styles'

export const prepareIcon = theme => {
  return {
    styleOverrides: {
      root: sx({
        '&.MuiIcon-fontSizeSmall': {
          width: theme.icons.small.width,
          height: theme.icons.small.height
        },
        '&.MuiIcon-fontSizeMedium': {
          width: theme.icons.medium.width,
          height: theme.icons.medium.height
        },
        '&.MuiIcon-fontSizeLarge': {
          width: theme.icons.large.width,
          height: theme.icons.large.height
        }
      })
    }
  }
}
