export const prepareCard = theme => {
  return {
    styleOverrides: {
      elevation: {
        boxShadow: theme.shadows[3]
      },
      variants: [
        {
          props: { variant: 'noBackground' },
          style: {
            boxShadow: theme.shadows[0],
            padding: 0
          }
        }
      ]
    }
  }
}
