import {
  LOGO_ONE_LINE,
  LOGO_DOUBLE_LINE,
  LOGO_SYMBOL,
  LOGO_DOUBLE_LINE_BIG,
  LOGO_QUSTION,
  LOGO_SEPHORA,
  LOGO_SEPHORA_DOUBLE_LINE,
  LOGO_SKIN_GENOME_QUIZ
} from '../../constants/logos'
export const prepareLogos = theme => {
  theme.logos = {
    [LOGO_SYMBOL]: {
      width: '0.85em'
    },
    [LOGO_DOUBLE_LINE]: {
      width: '4.17em',
      height: '2.21em'
    },
    [LOGO_DOUBLE_LINE_BIG]: {
      width: '7.84em',
      height: '4.17em'
    },
    [LOGO_ONE_LINE]: {
      width: {
        xs: '8.983rem',
        md: '11.25rem'
      }
    },
    [LOGO_QUSTION]: {
      width: {
        xs: '8.983rem',
        md: '11.25rem'
      }
    },
    [LOGO_SEPHORA]: {
      width: {
        xs: '14.5rem',
        md: '20.5rem'
      }
    },
    [LOGO_SEPHORA_DOUBLE_LINE]: {
      width: '8.983rem',
      height: '8rem'
    },
    [LOGO_SKIN_GENOME_QUIZ]: {
      width: {
        xs: '7.5rem',
        md: '14.5rem'
      }
    }
  }
  return theme
}
