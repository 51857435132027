let memoizedCallPostPreview = null
const memoize = (fn, expirationTime = 20000, size = 1) => {
  const cache = {}
  const keys = []

  return async inputObject => {
    const serializedInput = JSON.stringify(inputObject)
    // const currentTime = moment()
    const currentTime = Date.now()

    // Check if the cache size has reached the limit
    if (keys.length >= size) {
      const oldestKey = keys.shift()
      delete cache[oldestKey]
    }

    if (
      cache[serializedInput] &&
      currentTime - cache[serializedInput].timestamp <= expirationTime
    ) {
      return cache[serializedInput].value
    } else {
      const result = await fn(inputObject)
      if (result) {
        cache[serializedInput] = { value: result, timestamp: currentTime }
        keys.push(serializedInput) // Add the key to the list of keys
      }
      return result
    }
  }
}

export const buildMemoizedCallPostPreview = callback => {
  if (!memoizedCallPostPreview) {
    // Set expiration time to 18000 milliseconds (3 mins)  and cache size to 10
    memoizedCallPostPreview = memoize(callback, 3 * 60000, 10)
  }

  return memoizedCallPostPreview
}
