import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

export const prepareBreadcrumbs = theme => {
  return {
    defaultProps: {
      separator: <NavigateNextIcon fontSize="small" />
    },
    styleOverrides: {
      separator: {
        color: theme.palette.gray.elysian,
        marginLeft: theme.spacing(1.25),
        marginRight: theme.spacing(1.25)
      }
    }
  }
}
