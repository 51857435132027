import { prepareButton } from './button'
import { prepareCheckbox } from './checkbox'
import { prepareBackdrop } from './backdrop'
import { prepareCard } from './card'
import { prepareChip } from './chip'
import { prepareAutocomplete } from './autocomplete'
import { prepareOutlinedInput } from './outlined-input'
import { prepareIconButton } from './icon-button'
import { prepareFormHelperText } from './form-helper-text'
import { prepareTooltip } from './tooltip'
import { prepareSvgIcon } from './svg-icon'
import { prepareDivider } from './divider'
import { prepareAppBar } from './app-bar'
import { prepareLink } from './link'
import { prepareToolBar } from './tool-bar'
import { prepareBadge } from './badge'
import { prepareIcon } from './icon'
import { prepareAlert, prepareAlertTitle } from './alert'
import { prepareLinearProgress } from './linear-progress'
import { prepareTextField } from './text-field'
import { prepareBreadcrumbs } from './breadcrumbs'
import { prepareRadio } from './radio'
import { prepareButtonBase } from './button-base'
import { preparePaper } from './paper'

export const prepareComponents = theme => {
  theme.components = {
    ...theme.components,
    MuiButton: prepareButton(theme),
    MuiButtonBase: prepareButtonBase(),
    MuiIconButton: prepareIconButton(theme),
    MuiCheckbox: prepareCheckbox(theme),
    MuiBackdrop: prepareBackdrop(),
    MuiCard: prepareCard(theme),
    MuiChip: prepareChip(theme),
    MuiOutlinedInput: prepareOutlinedInput(theme),
    MuiAutocomplete: prepareAutocomplete(theme),
    MuiFormHelperText: prepareFormHelperText(theme),
    MuiTooltip: prepareTooltip(theme),
    MuiSvgIcon: prepareSvgIcon(theme),
    MuiDivider: prepareDivider(theme),
    MuiAlert: prepareAlert(theme),
    MuiAlertTitle: prepareAlertTitle(theme),
    MuiLinearProgress: prepareLinearProgress(theme),
    MuiAppBar: prepareAppBar(theme),
    MuiLink: prepareLink(theme),
    MuiToolbar: prepareToolBar(theme),
    MuiBadge: prepareBadge(theme),
    MuiIcon: prepareIcon(theme),
    MuiTextField: prepareTextField(theme),
    MuiBreadcrumbs: prepareBreadcrumbs(theme),
    MuiRadio: prepareRadio(theme),
    MuiPaper: preparePaper(theme)
  }

  return theme
}
