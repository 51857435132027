import React from 'react'
import { experimental_sx as sx } from '@mui/material/styles'
import { createSvgIcon } from '@mui/material/utils'

//TODO: use ProvenIcon component when it is ready
const SystemCrossCloseIcon = createSvgIcon(
  <path d="M8.81836 7.40422L13.7343 2.48828L15.1485 3.90249L10.2326 8.81843L15.147 13.7329L13.7328 15.1471L8.81836 10.2326L3.90398 15.147L2.48977 13.7328L7.40415 8.81843L2.48828 3.90257L3.90249 2.48836L8.81836 7.40422Z" />,
  'SystemCrossClose'
)

export const prepareChip = theme => {
  return {
    defaultProps: {
      deleteIcon: <SystemCrossCloseIcon viewBox="0 0 18 18" />
    },
    styleOverrides: {
      root: sx({
        height: 26,
        ...theme.typography.footnote,
        color: theme.palette.gray.elysian,
        backgroundColor: theme.palette.primary.current,
        px: 1.5,
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main
        },
        '&:active': {
          backgroundColor: theme.palette.gray.blueGray,
          color: theme.palette.primary.contrastText
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.gray.lightGray,
          color: theme.palette.gray.middleGray,
          opacity: 1,
          '& .MuiChip-deleteIcon': {
            color: theme.palette.gray.middleGray
          }
        },
        '&.MuiChip-colorInfo': {
          color: theme.palette.gray.elysian,
          backgroundColor: theme.palette.background.lightBlue,
          '&:hover': {
            color: theme.palette.gray.elysian,
            backgroundColor: theme.palette.background.lightBlue
          },
          '&:active': {
            color: theme.palette.gray.elysian,
            backgroundColor: theme.palette.background.lightBlue
          }
        },
        '&.MuiChip-colorPrimary': {
          color: theme.palette.gray.blueGray,
          backgroundColor: theme.palette.primary.ether,
          '&:hover': {
            color: theme.palette.gray.blueGray,
            backgroundColor: theme.palette.primary.ether
          },
          '&:active': {
            color: theme.palette.gray.blueGray,
            backgroundColor: theme.palette.primary.ether
          }
        }
      }),
      label: sx({
        px: 0
      }),
      deleteIcon: sx({
        fontSize: 14,
        color: theme.palette.gray.elysian,
        margin: `0 0 0 ${theme.spacing(1)}`,
        '&:hover': {
          color: theme.palette.gray.elysian
        }
      })
    }
  }
}
