export const colors = {
  'color-white': '#ffffff',
  'color-black': '#323232',
  'color-black-light': '#4a4a4a',
  'color-teal': '#b9dcd2',
  'color-purple': '#0D06B1',
  'color-red': '#c13b4c',
  'color-text': '#181918',
  'color-gray-dark': '#6e7c8d',
  'color-gray': '#9b9b9b',
  'color-gray-light': '#f7f7f7',
  'color-gray-border': '#ECECEC',
  'color-gray-stroke': '#A1A1A1',
  'color-blue-light': '#eaeef4',
  'color-blue': '#0D06B1',
  'color-blue-dark': '#256376',
  'color-brown': '#ecd0bf',
  'color-brown-light': '#f7efea',
  'color-green': '#5fd1ae',
  'color-green-light': '#d3e5df',
  'color-green-light-bg': '#EFF9F4',
  'color-green-clay': '#59A89A',
  'color-red-clay': '#a85959',
  'color-red-clay-dark': '#7d2c28',
  'color-yellow': '#FFEA79',
  'color-yellow-orange': '#e39e2e',
  'color-yellow-orange-dark': '#ca8719',
  'color-background': '#F9F6EE',
  'color-accent-brown': 'rgba(168, 89, 89, 1)',
  'color-accent-orange': 'rgba(227, 156, 50, 1)',
  'color-accent-light-brown': '#DEC9BA',
  'color-press-blue': 'rgba(140, 150, 158, 1)',
  'color-accent-error': 'rgba(218, 55, 46, 1)',
  'color-accent-green': '#7BC4AB',
  border: '#D9D9D9',
  connectBackground: '#F6F6F6'
}

colors.incomingChatBackground = '#e5e5e5'
colors.outcomingChatBackground = '#7BC4AB'

export const sizes = {
  xxxl: '4.25rem',
  xxl: '3rem',
  xl: '2.25rem',
  lg: '1.5rem',
  md: '1.25rem',
  sm: '1.125rem',
  xs: '1rem',
  xxs: '0.875rem',
  xxxs: '0.75rem',
  'padding-lg': '0.5rem',
  padding: '0.375rem',
  'content-width': '1440px'
}

export const desktopSizes = {
  'size-1024': '64rem',
  'size-512': '32rem',
  'size-256': '16rem',
  'size-172': '10.75rem',
  'size-128': '8rem',
  'size-96': '6rem',
  'size-72': '4.5rem'
}

export default {
  ...colors,
  ...sizes,
  ...desktopSizes
}
