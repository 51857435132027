import { experimental_sx as sx } from '@mui/material/styles'

export const prepareTextField = theme => {
  return {
    styleOverrides: {
      root: sx({
        color: theme.palette.gray.elysian,

        '& .MuiOutlinedInput-root.MuiInputBase-colorPrimary': {
          '& fieldset': {
            borderColor: `${theme.palette.gray.middleGray} !important`,
            color: `${theme.palette.gray.middleGray} !important`
          },
          '&:hover fieldset': {
            borderColor: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.primary.main} !important`
          },
          '&.Mui-focused fieldset': {
            borderColor: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.primary.main} !important`
          }
        },
        '& .MuiOutlinedInput-root.MuiInputBase-colorSuccess': {
          '& fieldset': {
            borderColor: `${theme.palette.success.main} !important`,
            color: `${theme.palette.success.main} !important`
          },
          '&:hover fieldset': {
            borderColor: `${theme.palette.success.main} !important`,
            color: `${theme.palette.success.main} !important`
          }
        },
        '& .MuiOutlinedInput-root.MuiInputBase-colorError': {
          '& fieldset': {
            borderColor: `${theme.palette.error.main} !important`,
            color: `${theme.palette.error.main} !important`
          },
          '&:hover fieldset': {
            borderColor: `${theme.palette.error.main} !important`,
            color: `${theme.palette.error.main} !important`
          }
        }
      })
    }
  }
}
