import {
  DEFAULT_SYSTEM_SUBSCRIPTION_PERIOD,
  DEFAULT_SUBSCRIPTION_PERIOD_UNIT,
  DEFAULT_EYE_CREAM_DUO_SUBSCRIPTION_PERIOD
} from 'constants/subscriptions'
import {
  DEFAULT_SERUM_SUBSCRIPTION_PERIOD,
  DEFAULT_SERUM_SUBSCRIPTION_PERIOD_IN_MONTHS
} from '../../constants/subscriptions'
import { COMBO_PRODUCTS, SERUM_PRODUCT } from '../../constants/products'

export function getDefaultProductFrequency(product) {
  if (!product?.metadata) {
    // eslint-disable-next-line no-console
    console.warn(
      `In getDefaultProductFrequency: ${
        !product ? 'product is: ' + product : 'product.metadata is: ' + product?.metadata
      } . It will return default System period`
    )
    return {
      frequency: DEFAULT_SYSTEM_SUBSCRIPTION_PERIOD,
      frequencyUnit: DEFAULT_SUBSCRIPTION_PERIOD_UNIT
    }
  }

  if (product?.id === SERUM_PRODUCT) {
    return {
      frequency: DEFAULT_SERUM_SUBSCRIPTION_PERIOD,
      frequencyUnit: DEFAULT_SUBSCRIPTION_PERIOD_UNIT
    }
  }
  if (!product?.metadata?.isSystem && !product?.metadata?.isPartOfSystem) {
    return {
      frequency: DEFAULT_EYE_CREAM_DUO_SUBSCRIPTION_PERIOD,
      frequencyUnit: DEFAULT_SUBSCRIPTION_PERIOD_UNIT
    }
  }

  return {
    frequency: DEFAULT_SYSTEM_SUBSCRIPTION_PERIOD,
    frequencyUnit: DEFAULT_SUBSCRIPTION_PERIOD_UNIT
  }
}

export function getPeriodFromEntityId(entityId) {
  if (!entityId) return null
  const entityStrs = entityId.split('-')
  const everyIndex = entityStrs.findIndex(st => st === 'every')
  if (everyIndex < 0) return null
  const period = parseInt(entityStrs[everyIndex + 1])
  return isNaN(period) ? null : period
}

/**
 * it returns true if subscription price of the product is in prices and price quantity is greater than 0
 * We also use products, to take into account the cases where the product has individual items (for instance System has Cleanser, Day cream and Night cream)
 * @param product
 * @param prices array of {priceId : string, priceData: object: {quantity: number, rudOrder: number, totalPrice: number, unitPrice: number}}
 * @param products
 * @returns {this is *[]|*}
 */
export function isProductSubscriptionPriceInPrices(product, prices, products) {
  const individualProductsToCheck = productToIndividualProductsOfSet(product, products)
  return individualProductsToCheck.every(p =>
    p?.subscription_prices?.some(sp =>
      prices.find(price => price.priceId === sp.id && price?.priceData?.quantity)
    )
  )
}

/**
 * It will return an array of products, if product param is a set product (based on product.metadata.isSetItem), it will return an array with the individual products, otherwise it will return an array containing only the product passed as parameter
 * @param product
 * @param products
 * @returns {*[]|({isSetItem}|*)[]}
 */
export function productToIndividualProductsOfSet(product, products) {
  if (!product?.metadata?.isSetItem || !product?.metadata?.set?.length) {
    return [product]
  }

  const indProdIds = new Set([...product.metadata.set])
  const indProducts = []
  for (let i = 0; i < products.length; i++) {
    if (indProdIds.has(products[i].id)) {
      indProducts.push(products[i])
      indProdIds.delete(products[i].id)
    }
    if (indProdIds.size === 0) {
      break
    }
  }
  return indProducts.length ? indProducts : [product]
}

/**
 * returns an object with product and isOneTime fields. If it is not found it returns null
 * @param priceId
 * @param products
 * @returns {null|{product: *, isOneTime: boolean, period: string, periodUnit: string, currencyCode: string}}
 */
export function findProductByPriceId(priceId, products) {
  if (!priceId) return null

  for (const product of products) {
    if (product?.one_time_price?.id === priceId) return { product, isOneTime: true }
    const subscriptionPrice = product?.subscription_prices?.find(price => price.id === priceId)
    if (subscriptionPrice)
      return {
        product,
        isOneTime: false,
        period: subscriptionPrice.period,
        periodUnit: subscriptionPrice.period_unit,
        currencyCode: subscriptionPrice.currency_code
      }
  }

  return null
}
