import { experimental_sx as sx } from '@mui/material/styles'

export const preparePaper = theme => {
  return {
    styleOverrides: {
      elevation: {
        boxShadow: theme.shadows[3]
      },
      noBackground: sx({
        backgroundColor: 'transparent',
        '.MuiCardMedia-img': {
          borderRadius: { xs: theme.spacing(1), md: theme.spacing(2) }
        }
      })
    }
  }
}
