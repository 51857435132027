export const prepareDivider = theme => {
  return {
    styleOverrides: {
      root: {
        borderColor: theme.palette.gray.blueGray
      },
      light: {
        borderColor: theme.palette.gray.lightGray
      },
      withChildren: {
        '&::before, &::after': {
          borderColor: theme.palette.gray.lightGray
        }
      },
      wrapper: {
        ...theme.typography.footnote,
        color: theme.palette.gray.blueGray
      },
      wrapperVertical: {
        ...theme.typography.footnote,
        color: theme.palette.gray.blueGray
      }
    }
  }
}
