import provenApi from 'services/proven-api'
import provenPay from 'services/proven-pay'
import { Auth } from 'aws-amplify'
import { checkUserExistsUrl } from 'constants/endpoints'
import { store } from 'store/configureStore'
import { failPasswordlessAuth } from 'actions/auth.actions'

async function requestInterceptor(config) {
  try {
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()
    if (token) {
      config.headers.Authorization = token
    }
    return config
  } catch (_err) {
    return config
  }
}

provenApi.interceptors.request.use(requestInterceptor)
provenPay.interceptors.request.use(requestInterceptor)

function responseInterceptor(response) {
  return response
}

const errorInterceptor = async error => {
  const originalRequest = error.config
  if (error?.response?.status === 401) {
    const session = await Auth.currentSession()
    const { email } = session.getIdToken().payload
    store.dispatch(failPasswordlessAuth(email))
    await Auth.signOut()
  }
  return Promise.reject(error)
}

provenApi.interceptors.response.use(responseInterceptor, errorInterceptor)
provenPay.interceptors.response.use(responseInterceptor, errorInterceptor)

export async function isPasswordlessAuthenticated() {
  try {
    await Auth.currentSession() // should throw if logged out
    const isPWAuth = await isPasswordAuthenticated()
    return !isPWAuth
  } catch (_err) {
    return false
  }
}

export async function isPasswordAuthenticated() {
  try {
    await Auth.currentAuthenticatedUser()
    return true
  } catch (_err) {
    return false
  }
}

export async function isAuthenticated() {
  const isPwAuth = await isPasswordAuthenticated()
  if (isPwAuth) return true
  return isPasswordlessAuthenticated()
}

export async function completeNewPasswordChallenge({ username, oldPassword, newPassword }) {
  try {
    const user = await Auth.signIn(username, oldPassword)
    if (user?.challengeName !== 'NEW_PASSWORD_REQUIRED') return
    return Auth.completeNewPassword(user, newPassword, { email: username })
  } catch (err) {
    throw new Error(err.message)
  }
}

export function checkValidUser(username) {
  return provenApi.get(checkUserExistsUrl, {
    params: { email: encodeURI(username) }
  })
}

export async function getUser(username) {
  //avoids getting an error when the user dosn't exist
  try {
    const { data } = await checkValidUser(username)
    return data
  } catch (e) {
    return null
  }
}
