/**
 * this function wait for x millSeconds
 * use:
 *      await waitFor(1000);
 * @param millSeconds
 * @returns {Promise<unknown>}
 */
export const waitFor = millSeconds => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, millSeconds)
  })
}

/**
 * this function execute fn and if it fails retries "maxTries" and wait for "delay" (in millseconds) before trying to run "fn" again
 * @param fn: function to be executed
 * @param maxTries: number of attempts
 * @param delay: waiting time between attempts
 * @returns {Promise<*|null>}
 */
export const runWithRetries = async (fn, { maxTries, delay }) => {
  let tries = 0

  while (tries < maxTries) {
    tries++
    try {
      const result = await fn()
      return result
    } catch (e) {
      // wait (delay)
      await waitFor(delay)
    }
  }

  return null
}

/**
 * returns a new object where first level of keys are in upperCase
 * @param anObject
 * @returns {{[p: string]: unknown}}
 */
export const keysToUpperCase = anObject =>
  Object.fromEntries(
    Object.entries(anObject).map(keyAndValue => [keyAndValue[0].toUpperCase(), keyAndValue[1]])
  )

/**
 * get the first n words of a string
 * @param str
 * @param n
 * @returns {*}
 */
export const getNWordStr = (str, n) => {
  return str.split(/\s+/).slice(0, n).join(' ')
}
