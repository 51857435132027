import { onDesktop } from '../responsive'

export const prepareOutlinedInput = theme => {
  return {
    defaultProps: {
      disableRipple: true
    },
    styleOverrides: {
      root: {
        ...theme.typography.body2,
        color: theme.palette.gray.elysian,
        '&.Mui-error': {
          color: theme.palette.error.main
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main
        },
        '&.Mui-disabled': {
          color: theme.palette.gray.middleGray
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          backgroundColor: theme.palette.gray.lightGray
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
          borderWidth: 1
        },
        '&.MuiInputBase-colorSuccess': {
          color: theme.palette.success.main
        },
        '&.MuiInputBase-colorSuccess .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.success.main
        },
        '&.MuiInputBase-colorSuccess button': {
          color: theme.palette.success.main
        }
      },
      input: {
        paddingTop: theme.spacing(1.375),
        paddingBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: theme.spacing(2.625),
        [onDesktop]: {
          height: theme.spacing(3),
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3)
        },
        '&.Mui-disabled': {
          zIndex: 1
        },
        '&::placeholder': {
          color: theme.palette.gray.middleGray,
          opacity: 1
        }
      },
      notchedOutline: {
        borderColor: theme.palette.gray.middleGray
      }
    }
  }
}
