import {
  DEFAULT_EYE_CREAM_DUO_SUBSCRIPTION_PRICE,
  DEFAULT_SYSTEM_SUBSCRIPTION_PRICE,
  VERSION
} from '../../constants/configs/version'
import {
  TRACK_EVENT_ADD_TO_CART,
  TRACK_EVENT_ADD_TO_CART_2,
  TRACK_EVENT_ORDER_COMPLETED,
  TRACK_CHECKOUT_TO_ORDER_COMPLETED_EVENTS
} from '../../constants/trackNames'
import { CDN_IMG } from '../../constants/endpoints'
import { provenTitle } from '../../constants/seo-constants'
import {
  SYSTEM_PRODUCT,
  CLEANSER_PRODUCT,
  EYE_CREAM_PRODUCT,
  NIGHT_CREAM_PRODUCT,
  DAY_MOISTURIZER_PRODUCT,
  NIGHT_EYE_CREAM_PRODUCT,
  DAY_EYE_CREAM_PRODUCT,
  SHOP_PRODUCT_CATEGORY_MAPPINGS,
  SERUM
} from '../../constants/products'
import provenApi from '../../services/proven-api'
import { store } from '../../store/configureStore'
import {
  formatPrice,
  formatPriceAsNumber,
  findPriceInProduct,
  findProductForPriceId
} from 'utils/helpers'
import {
  countryCodeSelect,
  defaultCountryCodeSelect,
  userInfoSelector,
  activeSubscriptionsSelect,
  authenticatedSelector,
  currencyBasedOnUserCountryProductsPrice,
  appEditionSelector,
  variantSelector,
  accountSelector
} from '../selectors'
import { getCurrencySymbolByCode } from '@provenai/shared'
import {
  getCachedTikTokId,
  UTM_TO_TRACK_TIKTOK,
  getClusterVersion
} from '../../hooks/useForAnalytics'
import Cookies from 'cookies-js'

export const isAnalyticsEnabled = () => window.analytics
const FE = 'FE'
const UX = 'UX'
const PAGEVIEW = 'pageview'
const SHARK_TANK_AIR_DATETIME = new Date('May 06, 2020 22:00:00')

const ITERABLE_EMAIL_CAMPAIGN_ID = 'iterableEmailCampaignId'
const ITERABLE_TEMPLATE_ID = 'iterableTemplateId'
const META_PIXEL_FBP_KEY = '_fbp'
const META_PIXEL_FBC_KEY = '_fbc'

const SYSTEM_SKU = 'SET-SYS-000'
const CLEANSER_SKU = 'SET-CLN-000'
const SPF_SKU = 'SET-SPF-000'
const NIGHT_CREAM_SKU = 'SET-CRM-000'
const EYE_CREAM_DUO_SKU = 'SET-DUO-000'
const SERUM_SKU = 'SET-SRM-000'

const SYSTEM_URL = 'https://www.provenskincare.com/personalized-system-2'
const CLEANSER_URL = 'https://www.provenskincare.com/personalized-face-cleanser'
const SPF_URL = 'https://www.provenskincare.com/personalized-day-moisturizer'
const NIGHT_CREAM_URL = 'https://www.provenskincare.com/personalized-night-cream'
const EYE_CREAM_DUO_URL = 'https://www.provenskincare.com/eye'
const DAY_EYE_CREAM_URL = 'https://www.provenskincare.com/personalized-day-eye-cream'
const NIGHT_EYE_CREAM_URL = 'https://www.provenskincare.com/personalized-night-eye-cream'
const SERUM_URL = 'https://www.provenskincare.com/serum'

const CATEGORY = 'skincare'
const AFFILIATION = 'PROVEN'

export const getUserId = () => {
  return localStorage.getItem('ajs_user_id')
}

export const getUserTraits = () => {
  const traits = localStorage.getItem('ajs_user_traits')
  return traits && traits.length && traits.length > 0 ? JSON.parse(traits) : {}
}

export const getAnonymousId = () => {
  return localStorage.getItem('ajs_anonymous_id')
}

export const generateMockId = () => {
  const mills = new Date().getMilliseconds()
  return mills.toString(15) + Math.random().toString(36).substr(2) + mills.toString(22)
}

export const parseTraits = account => {
  return {
    traits: {
      email: account && account.info && account.info.email ? account.info.email : undefined,
      firstName:
        account && account.shippingAddress && account.shippingAddress.firstName
          ? account.shippingAddress.firstName
          : account && account.info && account.info.firstName
          ? account.info.firstName
          : undefined,
      lastName:
        account && account.shippingAddress && account.shippingAddress.lastName
          ? account.shippingAddress.lastName
          : account && account.info && account.info.lastName
          ? account.info.lastName
          : undefined,
      address: {
        city:
          account && account.shippingAddress && account.shippingAddress.city
            ? account.shippingAddress.city
            : undefined,
        state:
          account && account.shippingAddress && account.shippingAddress.state
            ? account.shippingAddress.state
            : undefined,
        postCode:
          account && account.shippingAddress && account.shippingAddress.zip
            ? account.shippingAddress.zip
            : account && account.info && account.info.zipCode
            ? account.info.zipCode
            : undefined
      },
      phone:
        account && account.shippingAddress && account.shippingAddress.phone
          ? account.shippingAddress.phone
          : account && account.info && account.info.phoneNumber
          ? account.info.phoneNumber
          : undefined
    }
  }
}

const MOCK_PRODUCT_INFO = {
  [SYSTEM_PRODUCT]: {
    sku: SYSTEM_SKU,
    postion: 1,
    url: SYSTEM_URL,
    price: DEFAULT_SYSTEM_SUBSCRIPTION_PRICE,
    image_url: `${CDN_IMG}product/3-bottles-set-images.png`,
    subscription: true
  },
  [CLEANSER_PRODUCT]: {
    sku: CLEANSER_SKU,
    postion: 3,
    url: CLEANSER_URL,
    price: 39.99,
    image_url: `${CDN_IMG}home/cleanser-individual-bottle.svg`,
    subscription: false
  },
  [DAY_MOISTURIZER_PRODUCT]: {
    sku: SPF_SKU,
    postion: 4,
    url: SPF_URL,
    price: 59.99,
    image_url: `${CDN_IMG}home/spf-individual-bottle.svg`,
    subscription: false
  },
  [NIGHT_CREAM_PRODUCT]: {
    sku: NIGHT_CREAM_SKU,
    postion: 5,
    url: NIGHT_CREAM_URL,
    price: 99.99,
    image_url: `${CDN_IMG}home/night-cream-individual-bottle.svg`,
    subscription: false
  },
  [EYE_CREAM_PRODUCT]: {
    sku: EYE_CREAM_DUO_SKU,
    postion: 1,
    url: EYE_CREAM_DUO_URL,
    price: DEFAULT_EYE_CREAM_DUO_SUBSCRIPTION_PRICE,
    image_url: `${CDN_IMG}rud/eye-cream-pdp-1-carousel1.jpg`,
    subscription: false
  },
  [NIGHT_EYE_CREAM_PRODUCT]: {
    sku: EYE_CREAM_DUO_SKU,
    position: 1,
    url: NIGHT_EYE_CREAM_URL,
    price: 94.99,
    image_url: `${CDN_IMG}rud/pdp-night-eye-cream-1.jpg`,
    subscription: false
  },
  [DAY_EYE_CREAM_PRODUCT]: {
    sku: EYE_CREAM_DUO_SKU,
    position: 1,
    url: DAY_EYE_CREAM_URL,
    price: 94.99,
    image_url: `${CDN_IMG}rud/pdp-day-eye-cream-1.jpg`,
    subscription: false
  },
  [SERUM]: {
    sku: SERUM_SKU,
    position: 1,
    url: SERUM_URL,
    price: 99.99,
    image_url: `${CDN_IMG}rud/serum-1.jpg`,
    subscription: true
  }
}

const getCountryCode = () => {
  const state = store.getState()

  return countryCodeSelect(state) || defaultCountryCodeSelect(state)
}

const getCurrencyAndSymbol = () => {
  const state = store.getState()

  let currency = currencyBasedOnUserCountryProductsPrice(state)
  if (!currency) {
    currency = 'USD'
  }

  let currencySymbol = getCurrencySymbolByCode(currency)

  if (!currencySymbol) {
    currency = 'USD'
    currencySymbol = '$'
  }

  return {
    currency,
    currencySymbol
  }
}

const getActiveSubscriptions = () => {
  const state = store.getState()

  return activeSubscriptionsSelect(state)
}

const getUserInfo = () => {
  const state = store.getState()

  return userInfoSelector(state)
}

const getAccount = () => {
  const state = store.getState()

  return accountSelector(state)
}

const getAuthentication = () => {
  const state = store.getState()

  return authenticatedSelector(state)
}

const getAppEdition = () => {
  const state = store.getState()

  return appEditionSelector(state)
}

const getVariant = () => {
  const state = store.getState()

  return variantSelector(state)
}

export const trackEvent = async (eventName, properties, context = {}) => {
  const { currency, currencySymbol } = getCurrencyAndSymbol()
  const tiktokId = getCachedTikTokId()
  const isAuth = getAuthentication()
  const appEdition = getAppEdition()
  const subscriptions = getActiveSubscriptions()
  const hasActiveSubscription = subscriptions.length > 0
  const userInfo = getUserInfo()
  const userId = userInfo?._id
  const userEmail = userInfo?.email
  const userSource = userInfo?.source
  const hasPurchased = userInfo?.hasPurchased
  const phoneNumber = userInfo?.phoneNumber
  const verifiedPhoneNumber = userInfo?.verifiedPhoneNumber
  const doubleOptInEmail = userInfo?.doubleOptInEmail
  const doubleOptInPhone = userInfo?.doubleOptInPhone
  const isOneTimeSystemPurchase = userInfo?.isOneTimeSystemPurchase
  const campaignId = parseInt(Cookies.get(ITERABLE_EMAIL_CAMPAIGN_ID))
  const templateId = parseInt(Cookies.get(ITERABLE_TEMPLATE_ID))
  // Meta Pixel Flags - should grab the flags based on current domain's cookie
  const fbp = Cookies.get(META_PIXEL_FBP_KEY)
  const fbc = Cookies.get(META_PIXEL_FBC_KEY)

  properties = properties || {}
  properties.version = VERSION
  properties.source = FE
  properties.event_type = UX
  properties.country = getCountryCode()
  properties.currency = currency
  properties.appEdition = appEdition
  if (isAuth) {
    properties.userId = userId
    properties.userEmail = userEmail
    properties.hasActiveSubscription = hasActiveSubscription
    properties.hasPurchased = hasPurchased
    properties.userSource = userSource
    properties.phoneNumber = phoneNumber
    properties.verifiedPhoneNumber = verifiedPhoneNumber
    properties.doubleOptInEmail = doubleOptInEmail
    properties.doubleOptInPhone = doubleOptInPhone
    // Only attach to track events outside of RUD
    if (
      !hasPurchased &&
      !hasActiveSubscription &&
      appEdition === 'sephora' &&
      isOneTimeSystemPurchase !== null
    ) {
      if (TRACK_CHECKOUT_TO_ORDER_COMPLETED_EVENTS.includes(eventName)) {
        properties.isOneTimeSystemPurchase = isOneTimeSystemPurchase
      }
    }
  }

  if ([TRACK_EVENT_ADD_TO_CART, TRACK_EVENT_ADD_TO_CART_2].includes(eventName)) {
    properties.add_to_cart_currencySymbol = currencySymbol
  } else {
    properties.currencySymbol = currencySymbol
  }
  if (tiktokId) properties[UTM_TO_TRACK_TIKTOK] = tiktokId
  if (campaignId) properties.campaignId = campaignId
  if (templateId) properties.templateId = templateId
  if (fbp) properties.fbp = fbp
  if (fbc) properties.fbc = fbc
  properties.clusterVersion = getClusterVersion()

  // const isAttentiveEvent = () => {
  //   return ['assessment_concern', 'signup', 'add_to_cart', 'Order Completed'].includes(eventName)
  // }

  if (isAnalyticsEnabled()) {
    // if (isAttentiveEvent()) {
    //   const traits =
    //     context && context.traits ? { ...context?.traits, ...getUserTraits() } : getUserTraits()
    //   if (context) {
    //     context['traits'] = traits
    //   } else {
    //     context['context'] = { traits: traits }
    //   }
    // }
    window.analytics.track(eventName, properties, context)
  }
}

export const trackQuizEvent = (eventName, properties, context = {}) => {
  const tiktokId = getCachedTikTokId()
  // Meta Pixel Flags - should grab the flags based on current domain's cookie
  const fbp = Cookies.get(META_PIXEL_FBP_KEY)
  const fbc = Cookies.get(META_PIXEL_FBC_KEY)
  const appEdition = getAppEdition()
  const userEmail = context?.traits?.email
  properties = properties || {}
  properties.version = VERSION
  properties.source = FE
  properties.event_type = UX
  if (tiktokId) properties[UTM_TO_TRACK_TIKTOK] = tiktokId
  if (fbp) properties.fbp = fbp
  if (fbc) properties.fbc = fbc
  properties.clusterVersion = getClusterVersion()
  properties.appEdition = appEdition
  properties.userEmail = userEmail

  if (isAnalyticsEnabled()) {
    window.analytics.track(eventName, properties, context)
  }
}

export const identifyUser = async () => {
  if (isAnalyticsEnabled()) {
    try {
      const isAuth = getAuthentication()
      const subscriptions = getActiveSubscriptions()
      const campaignId = parseInt(Cookies.get(ITERABLE_EMAIL_CAMPAIGN_ID))
      const templateId = parseInt(Cookies.get(ITERABLE_TEMPLATE_ID))
      const hasActiveSubscription = isAuth ? subscriptions.length > 0 : null
      const { sephoraStoreId, sephoraAdvisorName } = getAccount()
      const { data } = await provenApi.get('user/get-properties', {
        params: {
          campaignId,
          templateId,
          hasActiveSubscription,
          sephoraStoreId,
          sephoraAdvisorName
        }
      })
      const country = getCountryCode()
      const tiktokId = getCachedTikTokId()
      // Meta Pixel Flags - should grab the flags based on current domain's cookie
      const fbp = Cookies.get(META_PIXEL_FBP_KEY)
      const fbc = Cookies.get(META_PIXEL_FBC_KEY)
      const userInfo = getUserInfo()
      const hasPurchased = isAuth ? userInfo?.hasPurchased : null
      const pathname = window.location.pathname
      window.analytics.alias(data.id)
      const properties = {
        ...data.properties,
        hasActiveSubscription,
        hasPurchased,
        country,
        clusterVersion: getClusterVersion()
      }
      if (tiktokId) properties[UTM_TO_TRACK_TIKTOK] = tiktokId
      if (campaignId) properties.campaignId = campaignId
      if (templateId) properties.templateId = templateId
      if (fbp) properties.fbp = fbp
      if (fbc) properties.fbc = fbc
      if (sephoraStoreId) properties.sephoraStoreId = sephoraStoreId
      if (sephoraAdvisorName) properties.sephoraAdvisorName = sephoraAdvisorName
      if (userInfo) {
        properties.phoneNumber = userInfo?.phoneNumber
        properties.verifiedPhoneNumber = userInfo?.verifiedPhoneNumber
        properties.doubleOptInEmail = userInfo?.doubleOptInEmail
        properties.doubleOptInPhone = userInfo?.doubleOptInPhone
      }
      // Fixes Identify bug: is called twice upon Log In
      if (pathname !== '/') {
        window.analytics.identify(data.id, properties)
      }
    } catch (e) {
      console.log(`identifyUser - ERROR: `, e) //eslint-disable-line
      //throw e;
    }
  }
}

export const identifyEmptyUser = async user => {
  if (isAnalyticsEnabled()) {
    try {
      const { sephoraStoreId, sephoraAdvisorName } = user
      const pathname = window.location.pathname
      window.analytics.alias(user.id)
      const properties = {
        ...user.info,
        clusterVersion: getClusterVersion()
      }
      if (sephoraStoreId) properties.sephoraStoreId = sephoraStoreId
      if (sephoraAdvisorName) properties.sephoraAdvisorName = sephoraAdvisorName

      // Fixes Identify bug: is called twice upon Log In
      if (pathname !== '/') {
        window.analytics.identify(user.id, properties)
      }
    } catch (e) {
      console.log(`identifyUser - ERROR: `, e) //eslint-disable-line
      //throw e;
    }
  }
}

export const trackPage = async (name, properties = {}) => {
  if (isAnalyticsEnabled()) {
    const tiktokId = getCachedTikTokId()
    // Meta Pixel Flags - should grab the flags based on current domain's cookie
    const fbp = Cookies.get(META_PIXEL_FBP_KEY)
    const fbc = Cookies.get(META_PIXEL_FBC_KEY)
    const subscriptions = getActiveSubscriptions()
    const isAuth = getAuthentication()
    const appEdition = getAppEdition()
    const hasActiveSubscription = subscriptions.length > 0
    const userInfo = getUserInfo()
    const userId = userInfo?._id
    const userEmail = userInfo?.email
    const userSource = userInfo?.source
    const hasPurchased = userInfo?.hasPurchased
    const variant = getVariant()
    let utmObj = {}

    if (window.location.search && window.location.search.toLowerCase().includes('utm')) {
      let pairs = window.location.search.substring(1).split('&')
      // eslint-disable-next-line
      for (let i in pairs) {
        if (pairs[i] === '' || !pairs[i].toLowerCase().startsWith('utm')) continue
        let pair = pairs[i].split('=')
        utmObj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
      }
    }

    if (isAuth) {
      utmObj.userId = userId
      utmObj.userEmail = userEmail
      utmObj.hasPurchased = hasPurchased
      utmObj.hasActiveSubscription = hasActiveSubscription
      utmObj.userSource = userSource
    }
    utmObj = Object.assign(utmObj, properties)

    if (
      !properties.url &&
      window &&
      window.location &&
      window.location.pathname &&
      process && //eslint-disable-line
      process.env && //eslint-disable-line
      process.env.BASE_URL //eslint-disable-line
    ) {
      utmObj.url = `${process.env.BASE_URL}${window.location.pathname}` //eslint-disable-line
    }

    if (!properties.path && window && window.location && window.location.pathname) {
      utmObj.path = `${window.location.pathname}`
    }

    if (!properties.title) {
      utmObj.title = provenTitle
    }

    utmObj.version = VERSION
    utmObj.source = FE
    utmObj.eventType = PAGEVIEW
    utmObj.country = getCountryCode()
    utmObj.variant = variant
    utmObj.appEdition = appEdition

    if (tiktokId) utmObj[UTM_TO_TRACK_TIKTOK] = tiktokId
    if (fbp) properties.fbp = fbp
    if (fbc) properties.fbc = fbc
    utmObj.clusterVersion = getClusterVersion()

    const millsSinceSharkTank = new Date() - SHARK_TANK_AIR_DATETIME
    const hoursSinceSharkTank = Math.floor(millsSinceSharkTank / (1000 * 60 * 60))
    utmObj.hoursSinceSharkTank = hoursSinceSharkTank

    window.analytics.page(name, utmObj)
  }
}

const buildProductObject = ({
  product,
  variant,
  price,
  oneTime,
  qty,
  analyticsName = 'skincare'
}) => {
  //console.log (`buildProductObject - product: `, product);
  return {
    id: product.id,
    brand: 'PROVEN',
    category: analyticsName,
    image_url: product.metadata.image,
    name: product.name,
    price,
    product_id: product.id,
    quantity: typeof qty === 'object' ? qty?.qty : qty,
    sku: product.metadata.mockSku,
    subscription: !oneTime,
    variant,
    url: `${location.origin}/${product.metadata.slug}`
  }
}

const buildProductObjectByName = (productId, coupon, variant, products, qty = 1) => {
  const mockProductInfo = MOCK_PRODUCT_INFO[productId]
  const category = SHOP_PRODUCT_CATEGORY_MAPPINGS[productId]
  const { currency, currencySymbol } = getCurrencyAndSymbol()

  const product = products?.find(someProduct => someProduct.id === productId)
  const productName = product?.name ?? ''

  return {
    id: productId,
    product_id: productId,
    sku: mockProductInfo.sku,
    category: category,
    name: productName,
    brand: 'PROVEN',
    variant: variant,
    price: mockProductInfo.price,
    value: mockProductInfo.price,
    currency,
    currencySymbol,
    quantity: qty,
    coupon: coupon,
    position: mockProductInfo.position,
    url: mockProductInfo.url,
    image_url: mockProductInfo.image_url,
    subscription: mockProductInfo.subscription,
    step: 1
  }
}

export const trackCouponEntered = (orderId, cartId, couponText, variant, account) => {
  trackEvent(
    'Coupon Entered',
    {
      order_id: orderId,
      cart_id: cartId,
      coupon_id: couponText,
      coupon_name: couponText,
      variant: variant
    },
    parseTraits(account)
  )
}

export const trackCouponDenied = (
  orderId,
  cartId,
  couponId,
  couponText,
  reason,
  variant,
  account
) => {
  trackEvent(
    'Coupon Denied',
    {
      order_id: orderId,
      cart_id: cartId,
      coupon_id: couponId,
      coupon_name: couponText,
      reason: reason,
      variant: variant
    },
    parseTraits(account)
  )
}

export const trackCouponApplied = (
  orderId,
  cartId,
  couponId,
  couponText,
  discount,
  variant,
  account
) => {
  const { currency, currencySymbol } = getCurrencyAndSymbol()

  trackEvent(
    'Coupon Applied',
    {
      order_id: orderId,
      cart_id: cartId,
      coupon_id: couponId,
      coupon_name: couponText,
      discount: discount,
      currency,
      currencySymbol,
      variant: variant
    },
    parseTraits(account)
  )
}

export const trackCouponRemoved = (
  orderId,
  cartId,
  couponId,
  couponText,
  discount,
  variant,
  account
) => {
  const { currency, currencySymbol } = getCurrencyAndSymbol()

  trackEvent(
    'Coupon Removed',
    {
      order_id: orderId,
      cart_id: cartId,
      coupon_id: couponId,
      coupon_name: couponText,
      discount: discount,
      currency,
      currencySymbol,
      variant: variant
    },
    parseTraits(account)
  )
}

export const trackPaymentInfoEntered = (
  checkoutId,
  cartId,
  orderId,
  shippingMethod,
  paymentMethod,
  orderDetails,
  variant,
  account
) => {
  const productsArr =
    orderDetails && orderDetails.lineItems
      ? orderDetails.lineItems.map(lineItem => ({
          id: lineItem.entityId,
          brand: AFFILIATION,
          entityType: lineItem.entityType,
          presentment_amount: Number(formatPrice(lineItem.amount)),
          price: Number(formatPrice(lineItem.amount)),
          quantity: lineItem.quantity
        }))
      : []

  const properties = {
    cart_id: cartId,
    checkout_id: checkoutId,
    order_id: orderId,
    shipping_method: shippingMethod,
    payment_method: paymentMethod,
    products: productsArr,
    step: 4,
    variant: variant
  }

  trackEvent('Payment Info Entered', properties, parseTraits(account))
}

export const trackCheckoutStepViewed = (
  checkoutId,
  cartId,
  step,
  shippingMethod,
  paymentMethod,
  orderDetails,
  variant,
  account
) => {
  const couponCodes = orderDetails?.couponCodes.flat() || []

  const productsArr =
    orderDetails && orderDetails.lineItems
      ? orderDetails.lineItems.map(lineItem => ({
          id: lineItem.entityId,
          brand: AFFILIATION,
          entityType: lineItem.entityType,
          presentment_amount: Number(formatPrice(lineItem.amount)),
          price: Number(formatPrice(lineItem.amount)),
          quantity: lineItem.quantity
        }))
      : []

  const properties = {
    cart_id: cartId ? cartId : generateMockId(),
    checkout_id: checkoutId ? checkoutId : generateMockId(),
    coupon: couponCodes?.join(', ') ?? '',
    payment_method: paymentMethod,
    products: productsArr,
    step: 3,
    shipping_method: shippingMethod,
    variant: variant
  }

  trackEvent('Checkout Step Viewed', properties, parseTraits(account))
}

export const trackCheckoutStarted = (
  cartId,
  checkoutId,
  coupon,
  variant,
  orderId,
  orderDetails,
  account = {},
  cartItems,
  products
) => {
  const productsArr = []
  Object.entries(cartItems).map(([priceId, qty]) => {
    const product = findProductForPriceId({ products, priceId })
    if (product) {
      const { price, oneTime } = findPriceInProduct({ product, priceId })
      const metaData = product.metadata ? product.metadata : {}
      const { analyticsName } = metaData

      productsArr.push(
        buildProductObject({
          product,
          variant,
          price: formatPriceAsNumber(price.price),
          oneTime,
          qty,
          analyticsName
        })
      )
    }
  })
  const properties = {
    affiliation: AFFILIATION,
    cartId,
    checkoutId,
    coupon: coupon,
    discount: orderDetails ? orderDetails.couponamountPretty : 0,
    order_id: orderId ? orderId : generateMockId(),
    products: productsArr,
    revenue: orderDetails ? Number(formatPrice(orderDetails.totalPreTax)) : 0,
    shipping: 0.0,
    source: FE,
    step: 3,
    tax: orderDetails ? orderDetails.taxamountPretty : 0,
    value: orderDetails ? Number(formatPrice(orderDetails.totalPreTax)) : 0,
    variant: variant
  }

  trackEvent('Checkout Started', properties, parseTraits(account))
}

export const trackCartViewed = ({ cartItems, products, coupon, variant, cartId, account }) => {
  trackEvent(
    'Cart Viewed',
    {
      cart_id: cartId ? cartId : generateMockId(),
      coupon: coupon,
      products: Object.entries(cartItems).map(([priceId, qty]) => {
        const product = findProductForPriceId({ products, priceId })
        const metaData = product.metadata ? product.metadata : {}
        const { analyticsName } = metaData
        const { price, oneTime } = findPriceInProduct({ product, priceId })
        return buildProductObject({
          product,
          variant,
          price: formatPriceAsNumber(price.price),
          oneTime,
          qty,
          analyticsName
        })
      }),
      step: 3,
      variant: variant
    },
    parseTraits(account)
  )
}

export const trackProductAdded = ({
  product,
  ctaText,
  qty,
  price,
  name,
  oneTime,
  coupon,
  variant,
  cartId,
  account = {}
}) => {
  const metaData = product.metadata ? product.metadata : {}
  const { analyticsName } = metaData
  const productObj = buildProductObject({
    product,
    variant,
    price,
    oneTime,
    qty,
    analyticsName
  })
  const properties = {
    brand: 'PROVEN',
    cart_id: cartId,
    coupon: coupon,
    product: productObj,
    step: 2,
    variant: variant
  }
  trackEvent('Product Added', properties, parseTraits(account))
}

export const trackProductsViewed = (
  list_id,
  variant,
  coupon,
  showProducts = [],
  account = {},
  products
) => {
  // eslint-disable-next-line
  for (let idx in showProducts) {
    trackProductViewed(list_id, variant, coupon, showProducts[idx], account, products)
  }
}

export const trackProductViewed = (
  list_id,
  variant,
  coupon,
  showProduct,
  account = {},
  products
) => {
  const product = buildProductObjectByName(showProduct, coupon, variant, products)
  trackEvent('Product Viewed', product, parseTraits(account))
}

export const trackProductListViewed = (list_id, variant, coupon, showProduct = 'all') => {
  let products = Object.keys(MOCK_PRODUCT_INFO).map(productName => {
    return buildProductObjectByName(productName, coupon, variant)
  })

  if (showProduct !== 'all') {
    products = [buildProductObjectByName(showProduct, coupon, variant)]
  }

  trackEvent('Product List Viewed', {
    list_id: list_id,
    category: CATEGORY,
    products,
    step: 1,
    variant: variant
  })
}

export const trackOrderCompleted = (
  orderCompletedObj,
  orderId,
  checkoutId,
  variant,
  account = {}
) => {
  //const { currency, currencySymbol } = getCurrencyAndSymbol()
  const {
    products,
    orderDetails: { couponCodes, couponAmount, lineItems, total, totalTaxAmount }
  } = orderCompletedObj
  const productsArr = lineItems
    .map(lineItem => {
      const { entityId: priceId } = lineItem
      const product = findProductForPriceId({ products, priceId })
      if (!product) {
        return null
      }

      const { price, oneTime } = findPriceInProduct({ product, priceId })

      const metadata = product.metadata ? product.metadata : {}
      const { analyticsName, imagePath, mockSku, chargeId } = metadata
      const imageUrl = imagePath ? CDN_IMG + imagePath : undefined

      // url hardcoded just to be backwards-compatible
      const productUrl = 'https://www.provenskincare.com/account/congratulations'
      return {
        category: analyticsName,
        id: product.id,
        imageUrl,
        name: product.name,
        price: formatPriceAsNumber(price.price),
        purchaseType: !oneTime ? priceId : chargeId,
        productId: product.id,
        quantity: lineItem.quantity,
        sku: mockSku,
        url: productUrl,
        subscription: !oneTime
      }
    })
    .filter(Boolean)
  const totalPretty = Number(formatPrice(total))
  const properties = {
    checkoutId,
    coupon: couponCodes.join(', '),
    discount: Number(formatPrice(couponAmount)),
    orderId,
    paymentMethod: account.paymentMethod.brand,
    subscription: lineItems.some(lineItem => lineItem.entityId.includes('weeks')),
    products: productsArr,
    revenue: totalPretty,
    share_copy: false,
    share_email: false,
    share_social: false,
    shipping: 0,
    step: 5,
    tax: Number(formatPrice(totalTaxAmount)),
    total: totalPretty,
    value: totalPretty,
    variant
  }
  trackEvent(TRACK_EVENT_ORDER_COMPLETED, properties, parseTraits(account))
}
