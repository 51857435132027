export const breakpoints = {
  xs: 0,
  sm: 375,
  md: 720,
  lg: 1280,
  xl: 1440,
  xxl: 1920
}

// export const onTablet = `@media (min-width: ${breakpoints.md}px) and (max-width: 1363px)`

export const onTablet = `@media (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px)`

export const onDesktop = `@media (min-width:${breakpoints.md}px)`

export const onLargeScreen = `@media (min-width:${breakpoints.lg}px)`

/**
 * for cases where you want to manually calculate the breakpoint on the basis of a width
 * @param width
 */
export const getBreakpoint = width => {
  switch (width) {
    case width < breakpoints.xs:
      return 'xs'
    case width < breakpoints.md:
      return 'sm'
    case width < breakpoints.lg:
      return 'md'
    case width < breakpoints.xl:
      return 'lg'
    case width < breakpoints.xxl:
      return 'xl'
    default:
      return 'xxl'
  }
}

export const breakpointsDown = (breakpoint, width) => {
  return width < breakpoints[breakpoint]
}
