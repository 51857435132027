export const prepareIcons = theme => {
  theme.icons = {
    large: {
      fontSize: '2.5rem',
      width: '2.5rem',
      height: '2.5rem'
    },
    medium: {
      fontSize: '1.5rem',
      width: '1.5rem',
      height: '1.5rem'
    },
    small: {
      fontSize: '1.125rem',
      width: '1.125rem',
      height: '1.125rem'
    }
  }
  return theme
}
