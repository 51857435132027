import { createSelector } from 'reselect'
import { pathOr, path, propOr, isEmpty, isNil } from 'ramda'
import { campaigns, checkCampaignSubscriptionStatus } from 'constants/configs/campaigns'
import {
  convertSubscriptionItemsToNonSystemItems,
  getProductById,
  hasRequiredAddressFields,
  isEyeCreamSubscription,
  isItemInCart,
  isSerumSubscription,
  isSystemSubscription
} from './helpers'
import { SKIP_THE_LINE_PRODUCT_ID } from '../constants/skip-the-line'
import {
  QUESTIONNAIRE_ID_EYE_CREAM_PASSWORDLESS,
  QUESTIONNAIRE_ID_SERUM,
  QUESTIONNAIRE_ID_SKIN_PASSWORDLESS,
  QUESTIONNAIRE_KEY_ZIPCODE
} from '../constants/configs/questionnaire'
import { getCurrencySymbolByCode, countries } from '@provenai/shared'

import { APP_EDITION_PROVEN } from '../constants/constants'
import {
  CLEANSER_PRODUCT,
  COMBO_PRODUCTS,
  DAY_EYE_CREAM_PRODUCT,
  DAY_MOISTURIZER_PRODUCT,
  EYE_CREAM_PRODUCT,
  NIGHT_CREAM_PRODUCT,
  NIGHT_EYE_CREAM_PRODUCT,
  ONE_TIME_OPTION_FOR_NEXT_ACTION,
  SERUM_PRODUCT,
  SUBSCRIPTION_OPTION_FOR_NEXT_ACTION,
  SYSTEM_PRODUCT
} from '../constants/products'
import { cloneDeep } from 'lodash'
import { keysToUpperCase } from './misc'
import { filterOrdersByMaxNumberOfMonths } from './orders'

// export const questionsSelector = pathOr([], ['quiz', 'questions'])
// export const questionsSelectorB = pathOr([], ['quiz', 'questions_b'])
// export const answersSelector = pathOr({}, ['quiz', 'answers'])
// export const quizCompletedSelector = pathOr(false, ['quiz', 'completed'])

// export const questionsSelectorEye = pathOr([], ['quiz', 'questions_6204508d40602c0a39f25419'])
// export const questionsSelectorBEye = pathOr([], ['quiz', 'questions_b_6204508d40602c0a39f25419'])
// export const answersSelectorEye = pathOr({}, ['quiz', 'answers_6204508d40602c0a39f25419'])
// export const quizCompletedSelectorEye = pathOr(false, [
//   'quiz',
//   'completed_6204508d40602c0a39f25419'
// ])
export const questionnairesSelector = () => pathOr({}, ['quiz', 'questionnaires'])

export const answersSelectorGeneric = (questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS) =>
  pathOr({}, ['quiz', 'questionnaires', questionnaireId, 'answers'])
export const questionsSelectorGeneric = (questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS) =>
  pathOr([], ['quiz', 'questionnaires', questionnaireId, 'questions'])
export const questionsBSelectorGeneric = (questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS) =>
  pathOr([], ['quiz', 'questionnaires', questionnaireId, 'questions_b'])

/* answers selectors */
export const answersSelector = answersSelectorGeneric()
export const answersSelectorEye = answersSelectorGeneric(QUESTIONNAIRE_ID_EYE_CREAM_PASSWORDLESS)
export const answersSelectorSerum = answersSelectorGeneric(QUESTIONNAIRE_ID_SERUM)
/* PLEASE ADD ANSWERS SELECTOR FOR NEW QUIZ HERE */

export const quizCompletedSelectorGenerics = (
  questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
) => pathOr(false, ['quiz', 'questionnaires', questionnaireId, 'completed'])
export const quizCompletedSelector = quizCompletedSelectorGenerics(
  QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
)
export const quizCompletedSelectorEye = quizCompletedSelectorGenerics(
  QUESTIONNAIRE_ID_EYE_CREAM_PASSWORDLESS
)
export const quizInvalidSelectorGenerics = (questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS) =>
  pathOr('', ['quiz', 'questionnaires', questionnaireId, 'invalid'])
export const quizAutocompleteInputValueSelectorGenerics = (
  questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
) => pathOr(false, ['quiz', 'questionnaires', questionnaireId, 'autocompleteInputValue'])
export const quizIngredientsSelectorGenerics = (
  questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
) => pathOr([], ['quiz', 'questionnaires', questionnaireId, 'ingredients'])
export const quizClinicalResultSelectorGeneric = (
  questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
) => pathOr([], ['quiz', 'questionnaires', questionnaireId, 'answers'])
export const clinicalResultsSelector = pathOr([], ['quiz', 'clinicalResults'])
export const quizResult = pathOr({}, ['quiz', 'result'])
export const quizError = pathOr(null, ['quiz', 'error'])
export const quizFollowUpQuestions = pathOr(null, ['quiz', 'followUpQuestions'])
export const quizFollowUpAnswers = pathOr(null, ['quiz', 'followUpAnswers'])

export const variantSelector = pathOr(null, ['app', 'experiments', 'App']) || 'B'

const compare = (value, rhs, op /*, defaultVal=false*/) => {
  // if (value === 'undefined' || typeof(value) === 'undefined' || value === undefined){
  //   return defaultVal;
  // }

  let result = false

  switch (op) {
    case 'lte': {
      result = value <= rhs
      break
    }
    case 'gte': {
      result = value >= rhs
      break
    }
    case 'ne': {
      result = value != rhs
      break
    }
    case 'eq': {
      result = value == rhs
      break
    }
    case 'contains': {
      if (Array.isArray(value)) {
        result = value.includes(rhs)
      } else {
        result = [value].includes(rhs)
      }
      break
    }
    case 'not_contains': {
      if (Array.isArray(value)) {
        result = !value.includes(rhs)
      } else {
        result = ![value].includes(rhs)
      }
      break
    }
  }

  return result
}

const getPathValue = (question, item, answers) => {
  let value = path([question, item], answers)
  if (!value || value === 'undefined' || typeof value === 'undefined' || value === undefined) {
    value = path([question], answers)
  }

  return value
}

export const filterQuestionsSelector = (variant = 'A', questionnaireId) =>
  createSelector(
    variant === 'A'
      ? questionsSelectorGeneric(questionnaireId)
      : questionsBSelectorGeneric(questionnaireId),
    answersSelectorGeneric(questionnaireId),
    (questions, answers) => {
      const filtered = questions.filter(question => {
        const { filter } = question
        let result = true //default show
        if (filter && Object.keys(filter).length > 0 && Object.keys(filter)[0] === 'or') {
          result = false
        }

        // eslint-disable-next-line
        for (const prop in filter) {
          switch (prop) {
            case 'and': {
              let r = true
              filter[prop].map(el => {
                const { question, item, op, rhs } = el
                const selectedVal = getPathValue(question, item, answers)
                r = r & compare(selectedVal, rhs, op)
              })

              result = !(result && r)
              break
            }
            case 'or': {
              let r = false
              filter[prop].map(el => {
                const { question, item, op, rhs } = el
                const selectedVal = getPathValue(question, item, answers)
                r = r | compare(selectedVal, rhs, op)
              })

              result = !(result || r)

              break
            }
          }
        }

        if (!filter || result) {
          return question
        }
      })

      return filtered
    }
  )

export const currentAnswerSelector = ({ name, questionnaireId }) => {
  const questionnaireIdKey = questionnaireId ? questionnaireId : QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
  return path(['quiz', 'questionnaires', questionnaireIdKey, 'answers', name])
}

export const currentAnswerSelectorById = ({ _id, questionnaireId }) => {
  const questionnaireIdKey = questionnaireId ? questionnaireId : QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
  return path(['quiz', 'questionnaires', questionnaireIdKey, 'answers', _id])
}

export const purchaseSelector = pathOr({}, ['purchase'])

export const formSelector = propOr({}, 'form')

export const checkoutSelector = propOr({}, 'checkout')

export const checkoutIdSelector = pathOr(undefined, ['checkout', 'mockCheckoutId'])

export const orderIdSelector = pathOr(undefined, ['checkout', 'mockOrderId'])

export const cartSelector = propOr({}, 'cart')

export const productsSelector = pathOr([], ['product', 'items'])
export const cartItemsSelector = pathOr({}, ['cart', 'items'])
export const cartAccessoriesSelector = pathOr([], ['cart', 'accessories'])
export const shopCartItemSelector = pathOr({}, ['shop', 'shopCart'])

// const quizSelector = pathOr({}, ['quiz'])

// export const quizPendingUserInfoSelector = createSelector(quizSelector, ({answers, pending}) => {
//   return {
//     answers,
//     ...pending
//   }
// })

export const shippingAddressSelector = createSelector(
  formSelector,
  ({ shipping: { values = {}, syncErrors } = {} }) => {
    if (!isNil(syncErrors) || Object.keys(values).length === 0) {
      return null
    }
    return values
  }
)

export const billingAddressSelector = createSelector(
  formSelector,
  ({ billing: { values = {}, syncErrors } = {} }) => {
    if (
      !isNil(syncErrors) ||
      Object.keys(values).length === 0 ||
      !values.country ||
      !values.state ||
      !values.zip ||
      !values.address1
    ) {
      return null
    }
    return values
  }
)

export const appEditionSelector = pathOr(APP_EDITION_PROVEN, ['app', 'appEdition'])

export const subscriptionOrOneTimeForNextActionOnCurrentProductPageSelector = pathOr(
  SUBSCRIPTION_OPTION_FOR_NEXT_ACTION,
  ['app', 'subscriptionOrOneTimeForNextActionOnCurrentProductPage']
)

export const isNextActionOnCurrentProductPageOneTimeSelector = createSelector(
  subscriptionOrOneTimeForNextActionOnCurrentProductPageSelector,
  subscriptionOrOneTime => subscriptionOrOneTime === ONE_TIME_OPTION_FOR_NEXT_ACTION
)

export const accountSelector = pathOr({}, ['account'])

export const userInfoSelector = createSelector(accountSelector, pathOr({}, ['info']))

export const paymentInfoSelector = createSelector(accountSelector, pathOr({}, ['paymentMethod']))

export const orderSummarySelector = pathOr({}, ['purchase', 'details', 'result', 'summary'])

export const accountShippingAddressSelector = pathOr({}, ['account', 'shippingAddress'])
export const accountBillingAddressSelector = pathOr({}, ['account', 'billingAddress'])

export const purchasePreviewPayloadSelector = createSelector(
  checkoutSelector,
  cartSelector,
  shippingAddressSelector,
  billingAddressSelector,
  accountShippingAddressSelector,
  accountBillingAddressSelector,
  (
    { coupon },
    { items },
    shippingAddressFromCheckoutForm,
    billingAddressFromCheckoutForm,
    accountShippingAddress,
    accountBillingAddress
  ) => {
    //when there is address data in form (checkout) we use it , in order to prioritize what user is entering, otherwise we use the data in account
    const shippingAddress = hasRequiredAddressFields(shippingAddressFromCheckoutForm)
      ? shippingAddressFromCheckoutForm
      : hasRequiredAddressFields(accountShippingAddress)
      ? accountShippingAddress
      : null

    const billingAddress = hasRequiredAddressFields(billingAddressFromCheckoutForm)
      ? billingAddressFromCheckoutForm
      : hasRequiredAddressFields(accountBillingAddress)
      ? accountBillingAddress
      : null

    return {
      shippingAddress,
      billingAddress,
      items: Object.entries(items).map(([itemPriceId, data]) => ({
        itemPriceId,
        quantity: data.qty,
        frequency: data.period
      })),
      coupon
    }
  }
)

export const dependentCouponCodeSelector = createSelector(
  checkoutSelector,
  ({ coupon, orderDetails }) => {
    if (!coupon || !orderDetails?.couponCodes?.length || orderDetails?.couponCodes?.length < 2)
      return null

    const dependentCouponCode = orderDetails.couponCodes.find(
      c => c?.trim().toUpperCase() !== coupon?.trim().toUpperCase()
    )
    return dependentCouponCode
  }
)

export const accessoriesPayloadSelector = createSelector(cartAccessoriesSelector, accessories => {
  return {
    items: Object.keys(accessories).map(itemPriceId => ({
      itemPriceId,
      quantity: 1
    }))
  }
})

export const checkoutPayloadSelector = createSelector(
  checkoutSelector,
  cartSelector,
  shippingAddressSelector,
  billingAddressSelector,
  ({ orderDetails }, { items }, shippingAddress, billingAddress) => {
    return {
      shippingAddress,
      billingAddress,
      items: Object.entries(items).map(([itemPriceId, data]) => ({
        itemPriceId,
        quantity: data.qty,
        frequency: data.period
      })),
      coupons: orderDetails?.couponCodes
    }
  }
)

export const invoiceLineItemsSelect = pathOr(
  [],
  ['checkout', 'invoiceDetails', 'invoice', 'lineItems']
)
export const isLastCheckoutASubscriptionSelector = createSelector(
  invoiceLineItemsSelect,
  lineItems => lineItems.some(item => item.entityType === 'addon_item_price')
)

export const isLastCheckoutASystemSubscriptionSelector = createSelector(
  invoiceLineItemsSelect,
  lineItems =>
    lineItems.some(
      item =>
        item.entityType === 'addon_item_price' &&
        item.entityId?.toLowerCase().includes(SYSTEM_PRODUCT.toLowerCase())
    )
)

export const skipTheLineInCartSelector = createSelector(
  productsSelector,
  cartItemsSelector,
  (products, cartItems) => {
    const skipTheLineProduct = getProductById(products, SKIP_THE_LINE_PRODUCT_ID)
    const isSkipTheLineInCart =
      !!skipTheLineProduct &&
      isItemInCart({
        priceId: skipTheLineProduct.one_time_price.id,
        cartItems
      })
    return { skipTheLineProduct, isSkipTheLineInCart }
  }
)

export const skipTheLineInShopCartSelector = createSelector(
  productsSelector,
  shopCartItemSelector,
  (products, shopCartItems) => {
    const skipTheLineProduct = getProductById(products, SKIP_THE_LINE_PRODUCT_ID)
    const isSkipTheLineInShopCart =
      !!skipTheLineProduct &&
      isItemInCart({
        priceId: skipTheLineProduct.one_time_price.id,
        cartItems: shopCartItems
      })
    return { skipTheLineProduct, isSkipTheLineInShopCart }
  }
)

export const purchaseOptionsSelector = createSelector(
  purchaseSelector,
  shippingAddressSelector,
  ({ subscription, coupon, skipTheLineValue }, { state }) => {
    return {
      subscribe: subscription === 'subscription',
      ...(!isEmpty(coupon) ? { coupon } : {}),
      state: state ? state.toUpperCase() : null,
      ...(!skipTheLineValue
        ? {}
        : {
            invoiceItems: [
              {
                id: 'skip-the-line',
                qty: 1
              }
            ]
          })
    }
  }
)

export const campaignEmailSelector = pathOr('', ['campaign', 'email'])

export const locationSelector = pathOr('', ['routing', 'location'])

export const authSelector = createSelector(locationSelector, ({ hash }) => {
  if (hash) {
    const params = new URLSearchParams(hash.substr(1))
    return {
      access_token: params.get('access_token'),
      id_token: params.get('id_token'),
      expires_in: params.get('expires_in'),
      token_type: params.get('token_type'),
      state: params.get('state')
    }
  } else {
    return {}
  }
})

export const authenticatedSelector = pathOr(false, ['auth', 'authenticated'])

const signupTimestampSelector = pathOr(null, ['auth', 'signupTimestamp'])

export const isThereRecentSignUpSelector = state => {
  const signupTimestamp = signupTimestampSelector(state)
  // we use a signup timestamp instead of a flag, in order to avoid the need of clean-up the flag, and make it simpler
  if (!signupTimestamp) {
    return false
  }
  const MAX_TIME_OF_CONSIDERING_SIGN_UP_PROCESS = 20 * 1000
  const res = Date.now() - signupTimestamp < MAX_TIME_OF_CONSIDERING_SIGN_UP_PROCESS
  return res
}

export const getAnswerByQuestionNameSelector = (
  name,
  variant = 'A',
  questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
) =>
  createSelector(
    answersSelectorGeneric(questionnaireId),
    variant === 'B'
      ? questionsBSelectorGeneric(questionnaireId)
      : questionsSelectorGeneric(questionnaireId),
    (answers, questions) => {
      const question = questions.find(q => {
        return q.question.name === name
      })

      return question &&
        question.question &&
        question.question._id &&
        answers[question.question._id]
        ? answers[question.question._id]
        : null
    }
  )

export const zipCodeSelector = createSelector(
  answersSelector,
  userInfoSelector,
  (answers, userInfo) => {
    const zipCode = answers[QUESTIONNAIRE_KEY_ZIPCODE]
    return userInfo.zipCode ?? zipCode
  }
)

/*  Answers request selectors  */
export const answersRequestSelector = createSelector(
  answersSelector,
  getAnswerByQuestionNameSelector('name'),
  userInfoSelector,
  (answers, nameAnswer, user) => {
    const zipCode = answers[QUESTIONNAIRE_KEY_ZIPCODE]
    const countryCode = answers['countryCode']
    return {
      ...user,
      firstName: (nameAnswer || user.firstName) ?? '',
      lastName: user.lastName ?? '',
      zipCode,
      countryCode,
      answers
    }
  }
)

export const answersEyeRequestSelector = createSelector(
  answersSelectorEye,
  getAnswerByQuestionNameSelector('name', 'A', QUESTIONNAIRE_ID_EYE_CREAM_PASSWORDLESS),
  userInfoSelector,
  (answers, nameAnswer, user) => {
    const zipCode = answers[QUESTIONNAIRE_KEY_ZIPCODE]
    const countryCode = answers['countryCode']
    return {
      ...user,
      firstName: (nameAnswer || user.firstName) ?? '',
      lastName: user.lastName ?? '',
      zipCode,
      countryCode,
      answers
    }
  }
)

export const answersSerumRequestSelector = createSelector(
  answersSelectorSerum,
  getAnswerByQuestionNameSelector('name', 'A', QUESTIONNAIRE_ID_SERUM),
  userInfoSelector,
  (answers, nameAnswer, user) => {
    const zipCode = answers[QUESTIONNAIRE_KEY_ZIPCODE]
    const countryCode = answers['countryCode']
    return {
      ...user,
      firstName: (nameAnswer || user.firstName) ?? '',
      lastName: user.lastName ?? '',
      zipCode,
      countryCode,
      answers
    }
  }
)
/* PLEASE ADD ANSWERS REQUEST SELECTOR FOR NEW QUIZ HERE */

/**
 * Account Selectors
 */

export const countryCodeSelect = pathOr('', ['account', 'info', 'countryCode'])

export const userSourceSelect = pathOr('', ['account', 'info', 'source'])

export const userCountryCodeSelect = pathOr('', [
  'account',
  'results',
  'user_information',
  'countryCode'
])

export const systemResultsSelect = pathOr({}, ['account', 'results'])
export const serumResultsSelect = pathOr({}, ['account', 'serumResults'])
export const eyeCreamResultsSelect = pathOr({}, ['account', 'eyeCreamQuizResult'])

export const serumProductAliasSelect = createSelector(serumResultsSelect, serumResults =>
  serumResults?.products?.length ? serumResults?.products[0]?.alias : undefined
)

export const cleanserProductAliasSelect = createSelector(systemResultsSelect, results => {
  if (!results?.products?.length) return undefined
  const product = results?.products.find(p => CLEANSER_PRODUCT === p.productPlanGroup)
  return product?.alias
})
export const dayMoisturizerProductAliasSelect = createSelector(systemResultsSelect, results => {
  if (!results?.products?.length) return undefined
  const product = results?.products.find(p => DAY_MOISTURIZER_PRODUCT === p.productPlanGroup)
  return product?.alias
})
export const nightCreamProductAliasSelect = createSelector(systemResultsSelect, results => {
  if (!results?.products?.length) return undefined
  const product = results?.products.find(p => NIGHT_CREAM_PRODUCT === p.productPlanGroup)
  return product?.alias
})

export const dayEyeCreamProductAliasSelect = createSelector(eyeCreamResultsSelect, eyeResults =>
  eyeResults?.products?.eye_day?.alias ? eyeResults?.products?.eye_day?.alias : undefined
)
export const nightEyeCreamProductAliasSelect = createSelector(eyeCreamResultsSelect, eyeResults =>
  eyeResults?.products?.eye_night?.alias ? eyeResults?.products?.eye_night?.alias : undefined
)

export const skuByIndividualProductIdSelect = createSelector(
  cleanserProductAliasSelect,
  dayMoisturizerProductAliasSelect,
  nightCreamProductAliasSelect,
  dayEyeCreamProductAliasSelect,
  nightEyeCreamProductAliasSelect,
  serumProductAliasSelect,
  (
    cleanserProductSku,
    dayMoisturizerProductSku,
    nightCreamProductSku,
    dayEyeCreamSku,
    nightEyeCreamSku,
    serumSku
  ) => {
    return {
      [CLEANSER_PRODUCT]: cleanserProductSku,
      [DAY_MOISTURIZER_PRODUCT]: dayMoisturizerProductSku,
      [NIGHT_CREAM_PRODUCT]: nightCreamProductSku,
      [DAY_EYE_CREAM_PRODUCT]: dayEyeCreamSku,
      [NIGHT_EYE_CREAM_PRODUCT]: nightEyeCreamSku,
      [SERUM_PRODUCT]: serumSku
    }
  }
)

export const defaultCountryCodeSelect = pathOr('', ['account', 'defaultCountryCode'])

// The priority is redux-form.shipping.country > account/shipping/coun > account.countryCode

/* only take the country from shipping address, if there is no country mean the user do not complete the shipping address */
export const countryCodeFromShipAddressSelect = createSelector(
  accountSelector,
  ({ shippingAddress }) => {
    return shippingAddress?.country
  }
)

// export const countryCodeSelectWithForm = createSelector(
//   formSelector,
//   accountSelector,
//   ({ shipping: formShippingAddress }, { shippingAddress, info }) => {
//     let country = ''
//     if (formShippingAddress?.values?.country) {
//       country = formShippingAddress?.values?.country
//     } else if (shippingAddress?.country) {
//       country = shippingAddress.country
//     }
//     return country || info.countryCode
//   }
// )

// export const currencySelectWithForm = createSelector(
//   countryCodeSelectWithForm,
//   accountSelector,
//   (countryCode, { currency }) => {
//     const country = countries.active.find(c => c.code === countryCode)
//     if (country) {
//       return country.provenCurrency || currency
//     }
//     return currency
//   }
// )

// export const currencySelect = createSelector(
//   countryCodeSelect,
//   accountSelector,
//   (countryCode, { currency }) => {
//     const country = countries.active.find(c => c.code === countryCode)
//     if (country) {
//       return country.provenCurrency || currency
//     }
//     return currency
//   }
// )

export const subscriptionOrdersInDraftSelect = pathOr([], ['account', 'subscriptionOrdersInDraft'])
export const subscriptionOrdersInAwaitingPaymentSelect = pathOr(
  [],
  ['account', 'subscriptionOrdersInAwaitingPayment']
)

export const isThereSubscriptionOrderToBeClosedSelector = createSelector(
  subscriptionOrdersInDraftSelect,
  subscriptionOrdersInAwaitingPaymentSelect,
  (subscriptionOrdersInDraft, subscriptionOrdersInAwaitingPayment) => {
    return subscriptionOrdersInDraft?.length || subscriptionOrdersInAwaitingPayment?.length
  }
)

export const subscriptionsSelect = pathOr([], ['account', 'subscriptions'])

export const activeSubscriptionsSelect = createSelector(subscriptionsSelect, subscriptions => {
  const activeSubscriptions = (subscriptions || []).filter(subs =>
    ['active', 'future', 'in_trial'].includes(subs.status)
  )
  return activeSubscriptions
})

export const lastActiveSkinSubscriptionSelect = createSelector(
  subscriptionsSelect,
  subscriptions => {
    const activeSkinSubscriptions = (subscriptions || []).filter(
      subs => ['active', 'future', 'in_trial'].includes(subs.status) && isSystemSubscription(subs)
    )
    activeSkinSubscriptions.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    return activeSkinSubscriptions.length ? activeSkinSubscriptions[0] : undefined
  }
)

export const lastActiveEyeSubscriptionSelect = createSelector(
  subscriptionsSelect,
  subscriptions => {
    const activeEyeSubscriptions = (subscriptions || []).filter(
      subs => ['active', 'future', 'in_trial'].includes(subs.status) && isEyeCreamSubscription(subs)
    )
    activeEyeSubscriptions.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    return activeEyeSubscriptions.length ? activeEyeSubscriptions[0] : undefined
  }
)

export const lastActiveSerumSubscriptionSelect = createSelector(
  subscriptionsSelect,
  subscriptions => {
    const activeSerumSubscriptions = (subscriptions || []).filter(
      subs => ['active', 'future', 'in_trial'].includes(subs.status) && isSerumSubscription(subs)
    )
    activeSerumSubscriptions.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    return activeSerumSubscriptions.length ? activeSerumSubscriptions[0] : undefined
  }
)

export const inactiveSubscriptionsSelect = createSelector(subscriptionsSelect, subscriptions => {
  const inactiveSubscriptions = (subscriptions || []).filter(
    subs => subs.status === 'cancelled' || subs.status === 'paused'
  )
  return inactiveSubscriptions
})

export const currencyInfoFromUserInfoSelector = createSelector(countryCodeSelect, countryCode => {
  const curr = countries.active.find(c => c.code === countryCode)
  const currency = curr ? curr.provenCurrency : 'USD'
  const currencySymbol = getCurrencySymbolByCode(currency)
  return {
    countryCode,
    currency,
    currencySymbol
  }
})

export const completedSkinQuizSelector = pathOr(false, ['account', 'completedSkinQuiz'])
export const completedEyeQuizSelector = pathOr(false, ['account', 'completedEyeQuiz'])
export const completedSerumQuizSelector = pathOr(false, ['account', 'completedSerumQuiz'])

export const ordersSelector = pathOr([], ['account', 'orders'])

const parseSystemSubscription = (subscription, store) => {
  if (!subscription) return null

  const preparedSub = cloneDeep(subscription)
  if (!preparedSub?.products) {
    preparedSub.products = store.product.items
  }

  let newItems = preparedSub?.items
  newItems = convertSubscriptionItemsToNonSystemItems({
    preparedSub,
    products: preparedSub.products
  })

  return { ...preparedSub, items: newItems }
}

export function getMostRecentActiveSystemSubscription(state) {
  const subscriptions = cloneDeep(subscriptionsSelect(state))
  const mostRecentSystemSubscription = subscriptions
    .filter(sub => ['active', 'future', 'in_trial'].includes(sub.status))
    .sort((a, b) => b.createdAt - a.createdAt)
    .find(sub => isSystemSubscription(sub))
  return (
    mostRecentSystemSubscription && parseSystemSubscription(mostRecentSystemSubscription, state)
  )
}

export const activeCampaignSelector = createSelector(
  getMostRecentActiveSystemSubscription,
  defaultCountryCodeSelect,
  (mostRecentSubscription, countryCode) => {
    // campaigns are only active for US/CA
    if (!['US', 'CA'].includes(countryCode)) return null

    const now = new Date().getTime()
    return campaigns.find(
      c =>
        now > Date.parse(c.startDateTime) &&
        now < Date.parse(c.endDateTime) &&
        checkCampaignSubscriptionStatus(c, mostRecentSubscription?.status)
    )
  }
)

export const orderDetailsByOrderIdSelector = pathOr({}, ['account', 'orderDetailsByOrderId'])

export const selectOrderDetailsByOrderId = createSelector(
  orderDetailsByOrderIdSelector,
  (_, orderId) => orderId, // this is the parameter passed from selector call
  (orderDetailsByOrderId, orderId) => orderDetailsByOrderId[orderId]
)

/** Shop Selectors **/

export const accessoriesSelector = pathOr([], ['shop', 'accessories'])

export const shopProductsSelector = pathOr([], ['shop', 'shopProducts'])

export const shopSubscriptionSelector = pathOr({}, ['shop', 'shopSubscription'])

export const combinedProductsSelector = state => {
  const products = state.product.items || []
  const creamDuoProducts = state.shop.shopProducts || []

  // products comes with duplicated elements (objects with the same id)
  // TODO on ENG-513 check the cause of this duplication and fix it where it's taking place, then remove this filter
  const { noDuplicatedProducts } = products.concat(creamDuoProducts).reduce(
    (idsAndProducts, p) => {
      if (!idsAndProducts.ids.has(p.id)) idsAndProducts.noDuplicatedProducts.push(p)
      idsAndProducts.ids.add(p.id)
      return idsAndProducts
    },
    { noDuplicatedProducts: [], ids: new Set() }
  )
  return noDuplicatedProducts
}

export const productByLowerCasePriceIdSelector = createSelector(
  combinedProductsSelector,
  allProducts => {
    const productByPriceId = allProducts.reduce((productByPriceIdAccOb, currentProduct) => {
      if (currentProduct?.one_time_price?.id)
        productByPriceIdAccOb[currentProduct.one_time_price.id.toLowerCase()] = currentProduct

      if (currentProduct.subscription_prices)
        currentProduct.subscription_prices.forEach(sp => {
          if (sp?.id) productByPriceIdAccOb[sp.id.toLowerCase()] = currentProduct
        })

      return productByPriceIdAccOb
    }, {})

    return productByPriceId
  }
)

/**
 * This selector returns an object where every key is the productId of the products that were included in last invoice (recent checkout). And every value is the sku (alias), that comes from results data,of that product..
 * For combo products like System and EyeCreamDuo, we replace these productId's by the individual products components
 * @type {OutputSelector<unknown, {}, (res1: unknown, res2: {"[DAY_EYE_CREAM_PRODUCT]": *, "[NIGHT_EYE_CREAM_PRODUCT]": *, "[DAY_MOISTURIZER_PRODUCT]": *, "[CLEANSER_PRODUCT]": *, "[SERUM_PRODUCT]": *, "[NIGHT_CREAM_PRODUCT]": *}) => {}>}
 */
export const skuOfLastCheckoutByProductIdSelector = createSelector(
  productByLowerCasePriceIdSelector,
  invoiceLineItemsSelect,
  skuByIndividualProductIdSelect,
  (productByLowerCasePriceId, lineItems, skuByIndividualProductId) => {
    const indProductIdsInLastCheckout = new Set()
    lineItems.forEach(item => {
      const product = productByLowerCasePriceId[item?.entityId?.toLowerCase()]
      if (!product?.id) {
        console.warn(
          `The priceId in invoice details line item: ${item?.entityId?.toLowerCase()} should be in productByLowerCasePriceId`
        )
        return
      }
      if (COMBO_PRODUCTS.includes(product.id)) {
        product?.metadata?.set?.forEach(indProductId =>
          indProductIdsInLastCheckout.add(indProductId)
        )
      } else {
        indProductIdsInLastCheckout.add(product.id)
      }
    })

    return Array.from(indProductIdsInLastCheckout).reduce(
      (skuOfLastCheckoutByProductIdAcc, currentIndProductId) => {
        skuOfLastCheckoutByProductIdAcc[currentIndProductId] =
          skuByIndividualProductId[currentIndProductId]
        return skuOfLastCheckoutByProductIdAcc
      },
      {}
    )
  }
)

/*export const shopCartSelector = pathOr([], ['shop', 'shopCart'])

export const shopOrderPreviewSelector = pathOr({}, ['shop', 'shopOrderPreview'])

export const shopCouponCodeSelector = pathOr(null, ['checkout', 'coupon']) // TODO we should store Shop coupons separately

  export const shopOrderPayloadSelector = createSelector(
  shippingAddressSelector,
  billingAddressSelector,
  shopCartSelector,
  //shopOrderPreviewSelector, // TODO revert to this when Shop coupons are stored under the Shop redux store
  shopCouponCodeSelector,
  (shippingAddress, billingAddress, shopProducts, coupon) => {
    return {
      shippingAddress,
      billingAddress,
      items: Object.entries(shopProducts).map(([itemPriceId, data]) => ({
        itemPriceId,
        quantity: data.qty,
        frequency: data.period
      })),
      coupons: [coupon].filter(Boolean)
    }
  }
)*/

export const currencyBasedOnUserCountryProductsPrice = createSelector(
  shopProductsSelector,
  shopProducts => {
    //TODO once we unify shop products and product items on ENG-901, we can start using products.
    // we use shopProducts since shopProducts endpoint returns products with currency based on backend countryCode
    const anyProductWithOneTimePriceCurrency =
      shopProducts?.length && shopProducts.find(p => p?.one_time_price?.currency_code)
    const currency = anyProductWithOneTimePriceCurrency?.one_time_price?.currency_code
    return currency
  }
)

export const resultsSelect = id => {
  switch (id) {
    case EYE_CREAM_PRODUCT:
      return eyeCreamResultsSelect
    case SERUM_PRODUCT:
      return serumResultsSelect
    default:
      return systemResultsSelect
  }
}

export const lastTwoMonthsOrdersSelect = createSelector(ordersSelector, orders =>
  filterOrdersByMaxNumberOfMonths(orders, 2)
)
