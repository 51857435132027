import React from 'react'

const DefaultIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="2.4165"
      y="2.41699"
      width="15.1667"
      height="15.1667"
      rx="3.25"
      stroke="#6B757D"
      strokeWidth="1.5"
    />
  </svg>
)

const CheckedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.6665" y="1.66699" width="16.6667" height="16.6667" rx="4" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7329 8.11176L8.76888 13.642L5.26074 10.3288L6.40511 9.11708L8.77933 11.3594L13.5996 6.88965L14.7329 8.11176Z"
      fill="white"
    />
  </svg>
)

export const prepareCheckbox = theme => {
  return {
    defaultProps: {
      disableRipple: true,
      checkedIcon: <CheckedIcon />,
      icon: <DefaultIcon />
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(1.25),
        color: theme.palette.gray.blueGray,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      },
      checked: {
        color: theme.palette.primary.main
      }
    }
  }
}
