import {
  QUESTIONNAIRE_KEY_NAME,
  QUESTIONNAIRE_KEY_ZIPCODE
} from '../../constants/configs/questionnaire'
import { getQuestionnaireInfo } from '../questionnaire-configurator'

export const getCongratPageUri = questionnaireId => {
  const questionnaireInfo = getQuestionnaireInfo(questionnaireId)
  const questionnaireInfoSkin = getQuestionnaireInfo()
  if (questionnaireInfo && questionnaireInfo?.congratsPageUri) {
    return questionnaireInfo.congratsPageUri
  } else {
    return questionnaireInfoSkin.congratsPageUri
  }
}

export const getQuizUriPrefix = questionnaireId => {
  const questionnaireInfo = getQuestionnaireInfo(questionnaireId)
  return questionnaireInfo.questionnaireUri?.endsWith('/')
    ? questionnaireInfo.questionnaireUri
    : questionnaireInfo.questionnaireUri + '/'
}

export const getAnswers = (questionnaireId, state) => {
  return getQuestionnaireInfo(questionnaireId).answersRequestSelector(state).answers
}

export const getNameFromAnswers = answers => {
  return answers[QUESTIONNAIRE_KEY_NAME]
}

export const getZipCodeFromAnswers = answers => {
  return answers[QUESTIONNAIRE_KEY_ZIPCODE]
}

export const getQuizTypeByQuestionnaireId = questionnaireId => {
  return getQuestionnaireInfo(questionnaireId).type
}
