// Utilities for Builder's content JS, intended to be executed within content JS.
// Adapted from https://github.com/buildquick/buildquick/blob/main/packages/builder-utils/src/lib/content-js.ts.
// See https://github.com/buildquick/buildquick/tree/main/packages/builder-utils for details.

import { Builder } from '@builder.io/sdk'

export const waitForElements = (selector, root = document.documentElement) => {
  return new Promise(resolve => {
    const elements = root.querySelectorAll(selector)

    if (elements.length) {
      resolve(elements)
      return
    }

    new MutationObserver((_mutationRecords, observer) => {
      const elements = root.querySelectorAll(selector)

      if (elements.length) {
        resolve(elements)
        observer.disconnect()
      }
    }).observe(root, {
      childList: true,
      subtree: true
    })
  })
}

export const waitForElement = (selector, root = document.documentElement) => {
  return new Promise(resolve => {
    const element = root?.querySelector(selector)

    if (element) {
      resolve(element)
      return
    }

    new MutationObserver((_mutationRecords, observer) => {
      const element = root?.querySelector(selector)

      if (element) {
        resolve(element)
        observer.disconnect()
      }
    }).observe(root, {
      childList: true,
      subtree: true
    })
  })
}

export const waitForRef = async (callback, ref) => {
  if (Builder.isBrowser) {
    if (!ref.ref && !Object.getOwnPropertyDescriptor(ref, 'ref')?.get) {
      Object.defineProperty(ref, 'ref', {
        get: function () {
          return this._ref
        },
        set: function (value) {
          this._ref = value
          callback(value)
        }
      })
    } else if (ref.ref) {
      callback(ref.ref)
    }
  }
}
