export const prepareSvgIcon = theme => {
  return {
    styleOverrides: {
      root: {
        fontSize: '1.125rem'
      },
      fontSizeSmall: {
        fontSize: theme.icons.small.fontSize
      },
      fontSizeMedium: {
        fontSize: theme.icons.medium.fontSize
      },
      fontSizeLarge: {
        fontSize: theme.icons.large.fontSize
      }
    }
  }
}
