export const customColors = {
  gray: {
    black: '#000000',
    elysian: '#303C42',
    gray: '#5D6469',
    blueGray: '#6B757D',
    middleGray: '#ACB1B3',
    lightGray: '#F2F1F1',
    whitesmoke: '#F5F5F5',
    beige: '#FAFAFA',
    lightBlue: '#F0F7FA',
    ether: '#D6EBF2',
    white: '#FFFFFF'
  },
  primary: {
    main: '#256376',
    blue: '#1877F2',
    light: '#D6EBF2',
    current: '#95C8D8',
    ether: '#D6EBF2',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#FFEA79',
    contrastText: '#303C42'
  },
  error: {
    main: '#CE2E47',
    secondary: '#FDF3F5'
  },
  success: {
    main: '#1A7030',
    secondary: '#F4FBF8'
  },
  warning: {
    main: '#FF9900',
    secondary: '#FDF3F5'
  },
  black: {
    main: '#000000',
    secondary: '#FFFFFF'
  }
}

export const brandTextColors = [
  'gray.elysian',
  'gray.white',
  'gray.gray',
  'gray.blueGray',
  'gray.middleGray',
  'primary.main',
  'error.main',
  'warning.main',
  'success.main',
  'secondary.main'
]
