import { onDesktop } from '../responsive'

export const prepareAlert = theme => {
  return {
    defaultProps: {
      icon: false
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(1),
        color: theme.palette.gray.gray,
        backgroundColor: theme.palette.gray.beige,
        alignItems: 'center',
        [onDesktop]: {
          padding: theme.spacing(4)
        }
      },
      message: {
        textAlign: 'left',
        padding: 0
      },
      standardSuccess: {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.secondary
      },
      standardError: {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.secondary
      },
      standardWarning: {
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.secondary
      },
      action: {
        padding: 0,
        margin: 0,
        '& svg': {
          fontSize: '1.125rem'
        }
      }
    }
  }
}

export const prepareAlertTitle = theme => {
  return {
    styleOverrides: {
      root: {
        textAlign: 'left',
        color: 'inherit',
        marginTop: 0,
        marginBottom: theme.spacing(1),
        [onDesktop]: {
          marginBottom: theme.spacing(2)
        }
      }
    }
  }
}
